import DynamicPropertyTable from "components/dynamic-property-table/dynamic-property-table";
import BasicForm from "components/dynamic-property/basic-form/basic-form";
import StaticPropertyTable from "components/static-property-table/static-property-table";
import CreateStaticProperty from "components/static-property/static-property";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  IDynamicPropertyDetails,
  IStaticPropertyDetails,
} from "store/model-properties/model-property.interface";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import "./property-console.scss";

function PropertyConsole() {
  const dispatch = useAppDispatch();
  const [dynamicPropertyFlag, setDynamicPropertyFlag] = useState(false);
  const [showStaticForm, setShowStaticForm] = useState<boolean>(false);
  const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
  const [showStatic, setShowStatic] = useState<string>("STATIC");

  const [staticPropertyDetails, setStaticPropertyDetails] = useState<
    IStaticPropertyDetails | undefined
  >(undefined);
  const [dynamicPropertyDetails, setDynamicPropertyDetails] = useState<
    IDynamicPropertyDetails | undefined
  >(undefined);

  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );

  const selectedModelDetails = useAppSelector(
    (state) => state.assetModels.selectedModel
  );
  useEffect(() => {
    dispatch(
      seqrOpsActions.updateNavigationState({
        ...navigationState,
        propertyConsoleStatic: true,
        propertyConsoleDynamic: false,
      })
    );
  }, []);

  useEffect(() => {
    setShowStaticForm(false);
    setShowDynamicForm(false);
    setDynamicPropertyFlag(false);
    setStaticPropertyDetails(undefined);
  }, [navigationState, selectedModelDetails?.id]);

  const handleClick = () => {
    if (navigationState.propertyConsoleStatic) {
      setDynamicPropertyFlag(false);
      setShowStaticForm(true);
    } else if (navigationState.propertyConsoleDynamic) {
      setDynamicPropertyFlag(true);
      setShowDynamicForm(true);
      setDynamicPropertyDetails(undefined);
    }

    return true;
  };

  const renderTable = () => {
    if (navigationState.propertyConsoleStatic) {
      if (!showStaticForm && !dynamicPropertyFlag) {
        return (
          <>
            <div className="seqrops-property-listing-section mt-3">
              <StaticPropertyTable
                modelId={selectedModelDetails?.id}
                staticPropertyDetails={staticPropertyDetails}
                showPropertyDetails={(
                  details: IStaticPropertyDetails | undefined
                ) => {
                  if (details) {
                    setShowStaticForm(true);
                    setStaticPropertyDetails(details);
                  } else {
                    setShowStaticForm(false);
                    setStaticPropertyDetails(undefined);
                  }
                }}
              />
            </div>
          </>
        );
      } else if (!dynamicPropertyFlag) {
        return (
          <>
            <CreateStaticProperty
              modelId={selectedModelDetails?.id || 0}
              showStaticForm={showStaticForm}
              setShowStaticForm={(state: boolean) => {
                setShowStaticForm(state);
                setStaticPropertyDetails(undefined);
              }}
              staticPropertyDetails={staticPropertyDetails}
            />
          </>
        );
      }
    } else if (navigationState.propertyConsoleDynamic) {
      if (!showDynamicForm && !dynamicPropertyFlag) {
        return (
          <>
            <DynamicPropertyTable
              modelId={selectedModelDetails?.id || 0}
              showDynamicPropertyDetails={(
                details: IDynamicPropertyDetails | undefined
              ) => {
                if (details) {
                  setShowDynamicForm(true);
                  setDynamicPropertyFlag(true);
                  setDynamicPropertyDetails(details);
                } else {
                  setShowDynamicForm(false);
                  setDynamicPropertyFlag(false);
                  setDynamicPropertyDetails(undefined);
                }
              }}
            />
          </>
        );
      } else if (dynamicPropertyFlag) {
        return (
          <div className="">
            <>
              <BasicForm
                modelId={selectedModelDetails?.id || 0}
                dynamicPropertyDetails={dynamicPropertyDetails}
                showDynamicForm={showDynamicForm}
                setShowDynamicForm={(state: boolean) => {
                  setShowDynamicForm(state);
                  setDynamicPropertyDetails(undefined);
                }}
                setDynamicPropertyFlag={(state: boolean) => {
                  setDynamicPropertyFlag(state);
                }}
              />
            </>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="seqrops-property-console-wrapper">
        <div className="container-fluid p-0">
          <div className="seqrops-property-outer-console-section mt-4">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="seqrops-model-name-section">
                <h4>{selectedModelDetails?.name}</h4>
              </div>
              <div className="seqrops-property-pattern-selection-section">
                <button
                  className={`me-2 ${showStatic === "STATIC" ? "active" : ""}`}
                  onClick={() => {
                    dispatch(
                      seqrOpsActions.updateNavigationState({
                        ...navigationState,
                        propertyConsoleStatic: true,
                        propertyConsoleDynamic: false,
                      })
                    );
                    setDynamicPropertyFlag(false);
                    setShowStaticForm(false);
                    setShowStatic("STATIC");
                  }}
                >
                  Static
                </button>
                <button
                  className={`me-2 ${showStatic === "DYNAMIC" ? "active" : ""}`}
                  onClick={() => {
                    dispatch(
                      seqrOpsActions.updateNavigationState({
                        ...navigationState,
                        propertyConsoleStatic: false,
                        propertyConsoleDynamic: true,
                      })
                    );
                    setDynamicPropertyFlag(false);
                    setShowDynamicForm(false);
                    setShowStatic("DYNAMIC");
                  }}
                >
                  Dynamic
                </button>
              </div>
            </div>
            {navigationState.propertyConsoleStatic || !dynamicPropertyFlag ? (
              <div className="seqrops-property-inner-console-section">
                <div className="seqrops-property-title col-md-3">
                  <h3>Property</h3>
                </div>
                <div className="seqrops-add-property-btn col-md-9">
                  {selectedModelDetails?.id ? (
                    <button
                      className="seqrops-btn-small seqrops-btn"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      + ADD
                    </button>
                  ) : (
                    <button
                      className="seqrops-btn-small seqrops-btn"
                      onClick={() => {
                        handleClick();
                      }}
                      disabled
                    >
                      + ADD
                    </button>
                  )}
                </div>
              </div>
            ) : null}
            {renderTable()}
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyConsole;

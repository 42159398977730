import closeBtn from "assets/images/seqrops_close_icon.svg";
import CreateRelationshipScreen from "modules/model-relation-crud/create-relation";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { ICreateModelRelationReq } from "store/asset-model/asset-model.interface";
import "./model-relation-console.scss";

interface IRelationForm {
  relationshipName: string;
  min: number | undefined;
  max: number | undefined;
  targetId: number | undefined;
  isAssoc: boolean;
}
function ModelRelationConsole({
  showModel,
  setShowModel,
  details,
}: {
  showModel: boolean;
  setShowModel: (state: boolean) => void;
  details: {
    sourceModelId: number;
    targetModelId: number;
  };
}) {
  const selector = useAppSelector((state) => state.assetModels.targetId);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState,
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm<IRelationForm>({
    defaultValues: {
      relationshipName: "",
      min: undefined,
      max: undefined,
      targetId: undefined,
      isAssoc: false,
    },
  });
  const errors: any = formState.errors;

  const handleClose = () => {
    setShowModel(false);
    reset();
  };

  const setSuccess = () => {
    handleClose();
  };
  const selectedModelId = useAppSelector(
    (state) => state.assetModels.selectedModel?.id
  );
  let createRelation: any = false;
  if (selectedModelId === selector) {
    createRelation = true;
  }
  // //handling save
  const handleSave = (relation: IRelationForm) => {
    relation.targetId = selector;
    if (selectedModelId !== selector) {
      if (!relation.targetId) {
        console.log("error");
        setError("targetId", {
          type: "custom",
          message: "Please select target model",
        });
        return;
      }
      if (relation.min && relation.max) {
        console.log(Number(relation.min));

        if (Number(relation.min) === 0 && Number(relation.max) === 0) {
          setError("min", {
            type: "custom",
            message: "Min and Max both cannot be zero",
          });
          return;
        }
        if (Number(relation.min) > Number(relation.max)) {
          setError("min", {
            type: "custom",
            message: "Min should be less than max",
          });
          return;
        }
      }


      const relationReq: ICreateModelRelationReq = {
        relationshipName: relation.relationshipName,
        min: relation.min || 1,
        max: relation.max || 1,
        targetId: relation.targetId,
        isAssoc: relation.isAssoc,
        associationId: relation.isAssoc ? relation.targetId : null,
        sourceId: details.sourceModelId,
      };

      dispatch(
        assetModelActions.createRelation(relationReq, setError, setSuccess)
      );
    }
  };
  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };
  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Create Relationship</Modal.Title>
            <button
              className="seqrops-relationship-popup-close-btn"
              onClick={() => {
                handleClose();
              }}
            >
              <img src={closeBtn} alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {/* <label htmlFor="modelName">Relationship</label> */}
            {/* <div className="d-flex">
              <Autocomplete
                id="seqrops-relation-selecting-dropdown"
                disableClearable
                onChange={(e, data) => {
                  onChange(data.id, assetRelationProperties);
                }}
                options={assetRelations}
                defaultValue={modelRelations[0]}
                getOptionLabel={(option: any) => option.relationName}
                renderInput={(params) => {
                  return <TextField {...params} key={params.id} label="" />;
                }}
              />
              <div className="div">
                <button
                  className="seqrops-new-relationship-add-button"
                  onClick={() => {
                    setRelationAddingFormFlag(!relationAddingFormFlag);
                  }}
                >
                  <img src={seqropsAddBtn} alt="seqrops add Btn" />
                </button>
              </div>
            </div> */}
            <>
              {/* <div className="seqrops-view-existing-relation-details-section">
                <div className="seqrops-relation-details-title-section">
                  <p>Has a property list</p>
                </div>
                <div className="seqrops-relation-details-section">
                  <label htmlFor="Name">Name</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.relationName}
                  </label>

                  <label htmlFor="targetType">Target type</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.targetName}
                  </label>

                  <label htmlFor="Source count">Source Count</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.srcCount}
                  </label>

                  <label htmlFor="Target count">Target Count</label>
                  <label className="property-item m-1">
                    {assetRelationProperties.targetCount}
                  </label>
                </div>
              </div> */}
            </>
            {/* ) : (
              <>
                <div className="seqrops-create-new-relationship-section">
                  <CreateRelationshipScreen
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    targetTypeSetTrigger={setDefaultTargetTypeId}
                    sourceModelId={sourceModelId}
                  />
                </div>
              </>
            )} */}
            <CreateRelationshipScreen
              selector={selector}
              register={register}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              createRelation={createRelation}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              className=" seqrops-btn btn-outline me-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="seqrops-btn btn-fill"
              onClick={handleSubmit(handleSave)}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ModelRelationConsole;

import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { staticPropertyValidation } from "../static-property-validation";

function MaxValueComponent({
  getValues,
  register,
  setError,
  errorWrap,
  errors,
  setValue,
  clearErrors,
}: {
  getValues: any;
  register: any;
  setError: any;
  setValue: any;
  clearErrors: any;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
}) {
  const selectedType = getValues("type");
  const dispatch = useDispatch()
  switch (selectedType) {
    case staticPropertyValidation.Types.BOOLEAN.value:
    case staticPropertyValidation.Types.ICON.value:
    case staticPropertyValidation.Types.MIMICS.value:
    case staticPropertyValidation.Types.DOCUMENT.value:
    case staticPropertyValidation.Types.IMAGE.value:
      return null;
    case staticPropertyValidation.Types.STRING.value:
      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Length
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^[0-9]*$"
            {...register("maxLength", {
              valueAsNumber: true,
              pattern: {
                value: /^\d*$/,
                message: "Please enter a valid max length",
              },
              min: {
                value: 0,
                message: "Max length should not be less than 0",
              },
              max: {
                value: 255,
                message:
                  "Max length should be less than or equal to 255 characters",
              },
            })}
            onChange={(e) => {
              const value = parseInt(e.target.value.split(/\D/).join(""));
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", isNaN(value) ? "" : value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.INTEGER.value:

      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Value
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^-\d+$"
            type="number"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("maxLength", {
              // required: "Please enter a  max value",
              // valueAsNumber: true,
              // pattern: {
              //   value: /^-\d+$/,
              //   message: "Please enter a valid max value",
              // },
              min: {
                value: -2147483648,
                message: "Max length should not be less than -2147483648",
              },
              max: {
                value: 2147483647,
                message:
                  "Max length should be less than or equal to 2147483647",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.SHORT.value:
      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Value
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^-\d+$"
            type="number"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("maxLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-\d+$/,
                message: "Please enter a valid max value",
              }, min: {
                value: -32768,
                message: "Max length should not be less than or equal to -32,768",
              },
              max: {
                value: 32767,
                message: "Max length should be less than or equal to 32,767",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.LONG.value:
      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Value
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^-\d+$"
            type="number"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("maxLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-\d+$/,
                message: "Please enter a valid max value",
              }, min: {
                value: -2147483648,
                message: "Min length should not be less than -2^63",
              },
              max: {
                value: 2147483647,
                message: "Max length should be less than or equal to 2^63-1",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.FLOAT.value:
      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Value
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^-?\d*\.{0,1}\d+$"
            type="number"
            step="any"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("maxLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-?\d*\.{0,1}\d+$/,
                message: "Please enter a valid max value",
              }, min: {
                value: -340282346638528859811704183484516925440.0000000000000000,
                message: "Min length should not be less than -3.402823E+38",
              },
              max: {
                value: 340282346638528859811704183484516925440.0000000000000000,
                message:
                  "Max length should be less than or equal to 3.402823E+38",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.DOUBLE.value:
      return (
        <>
          <label className="form-label" htmlFor="maxLength">
            Max Value
          </label>
          <input
            className="form-control"
            id="maxLength"
            pattern="^-?\d*\.\d+$"
            type="number"
            step="any"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("maxLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-?\d*\.{0,1}\d+$/,
                message: "Please enter a valid max value",
              }, min: {
                value: 2e-1074,
                message: "Min length should not be less than 2^-1074 ",
              },
              max: {
                value: (2 - (2e-52)) * 2e1023,
                message:
                  "Max length should be less than or equal to 2^1023",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("maxLength");
              dispatch(setIsDirty(true))
              setValue("maxLength", value);
            }}
          />
          <>{errorWrap(errors?.maxLength?.message)}</>
        </>
      );

    default:
      return null;
  }
}

export default MaxValueComponent;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModelPropertySlice } from "./model-property.interface";

//initial state
const initialState: IModelPropertySlice = {
  properties: [],
  dynamicProperties: [],
  units: [],
};

const modelPropertySlice = createSlice({
  name: "modelProperties",
  initialState: initialState,
  reducers: {
    setStaticProperties: (state, action: PayloadAction<any>) => {
      state.properties = [...action.payload];
    },
    setDynamicProperties: (state, action: PayloadAction<any>) => {
      state.dynamicProperties = [...action.payload];
    },
    setUnits: (state, action: PayloadAction<any>) => {
      state.units = [...action.payload];
    }
  },
});

export const { setStaticProperties } = modelPropertySlice.actions;
export const { setDynamicProperties } = modelPropertySlice.actions;
export const { setUnits } = modelPropertySlice.actions;

export default modelPropertySlice.reducer;

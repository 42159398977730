import seqropsNoModelIcon from "assets/images/seqrops_no_model_found.svg";
import CreateModelScreen from "modules/asset-model-crud/create-model";
import { useState } from "react";
import "./landing-screen.scss";

function LandingScreen() {
  const [showModel, setShowModel] = useState(false);

  return (
    <>
      <div className="seqrops-inital-page-wrapper">
        <div className="container">
          <div className="seqrops-no-model-image-section">
            <img src={seqropsNoModelIcon} alt="seqrops no-model icon" />
          </div>
          <div className="seqrops-no-model-image-text-section">
            No Models Found
          </div>
          <div className="seqrops-create-model-button-section">
            <button
              className="seqrops-btn btn-outline me-2"
              onClick={() => {
                setShowModel(true);
              }}
            >
              Create Model
            </button>
          </div>
          <CreateModelScreen
            showModel={showModel}
            setShowModel={setShowModel}
            modelDetails={undefined}
          ></CreateModelScreen>
        </div>
      </div>
    </>
  );
}

export default LandingScreen;

import axios from "axios";
import {
  ASSET_UMS_API,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  SESSION_KEY,
} from "config/default.config";
import { store } from "store";
import { ITokenDetails } from "store/keycloak/asset-keycloak.interface";
import { setTokens } from "store/keycloak/asset-keycloak.slice";
import { encryptUtils } from "./encryption.utils";

const initialize = () => {
  let data : any;
  
  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      let tokenDataEncrypted :any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
      let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
      // if(tokenData !== null){
      //   data = tokenData
      //     const token = data.token?.auth_token;
      //     if (token) {
      //       config.headers["Authorization"] = "Bearer " + token;
      //     }
      //     config.headers["Content-Type"] = "application/json";
      //   }
        return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error: any) {
      const originalRequest = error.config;

      if (
        error?.response?.status === 401 &&
        originalRequest.url === ASSET_UMS_API.GET_LOGIN_TOKEN
      ) {
        return Promise.reject(error);
      }

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken =  data?.token?.refresh_token;

        const params = new URLSearchParams();
        params.append("client_id", data?.token?.client_id || "");
        params.append(
          "client_secret",
          data?.token?.client_secret || ""
        );
        params.append("grant_type", KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
        params.append("refresh_token", refreshToken || "");

        return axios.post(ASSET_UMS_API.GET_LOGIN_TOKEN, params).then((res) => {
          if (res.status === 201) {
            let auth_token = res.data.access_token;
            let refresh_token = res.data.refresh_token;
            const ITokenDetails: ITokenDetails = {
              auth_token: auth_token,
              refresh_token: refresh_token,
              client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
              client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
              username: data?.token?.username || "",
              password: data?.token?.password || "",
            };
            store.dispatch(setTokens(ITokenDetails));
            
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + auth_token;
            return axios(originalRequest);
          }
        });
      }
      // return Promise.reject(error);
    }
  );
};

export const interceptiorUtils = {
  initialize,
};

import { Tooltip } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { IModelTreeActions } from "../model-tree.types";
import NodeToggle from "./node-toggle";

function NodeActionMenu({
  id,
  name,
  isRootNode,
  description,
  isEquipment,
  icon,
  isEdge,
  actions,
}: {
  id: number;
  name: string;
  isRootNode: boolean;
  description:string;
  isEquipment:boolean;
  icon:FileList|string;
  isEdge: boolean;
  actions: IModelTreeActions;
}) {
  return (
    <>
      <div className="seqrops-node-option-section d-inline-flex">
        <Tooltip title="options">
          <>
            <Dropdown className="node-options" drop="end">
              <Dropdown.Toggle
                className="removecaret"
                id="dropdown-autoclose-true"
                as={NodeToggle}
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {isEdge ? (
                  <Dropdown.Item
                    onClick={() => {
                      actions.deleteRelation(id, name);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item
                      onClick={() => {                     
                        actions.updateModel({ id, name, isRootNode,description,isEquipment,icon});
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        actions.deleteModel({ id, name, isRootNode,description,isEquipment,icon });
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        </Tooltip>
      </div>
    </>
  );
}

export default NodeActionMenu;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssetModelSlice, IModelListItem } from "./asset-model.interface";

//initial state
const initialState: IAssetModelSlice = {
  rawModels: [],
  models: [],
  selectedModels: [],
  modelHierarchy: [],
  selectedModel: undefined,
  targetId:0
};

const assetModelSlice = createSlice({
  name: "assetModels",
  initialState: initialState,
  reducers: {
    setModels: (state, action: PayloadAction<IModelListItem[]>) => {
      state.rawModels = [...action.payload];
      state.models = [...action.payload];
      state.selectedModels = [];
    },
    setFilteredModels: (
      state,
      action: PayloadAction<{
        filteredModels: IModelListItem[];
        filteredSelectedModels: IModelListItem[];
      }>
    ) => {
      state.models = [...action.payload.filteredModels];
      state.selectedModels = [...action.payload.filteredSelectedModels];
    },
    setModelHierarchy: (state, action: PayloadAction<any>) => {
      state.modelHierarchy = action.payload;
    },
    updateSelectedModel: (
      state,
      action: PayloadAction<IModelListItem | undefined>
    ) => {
      state.selectedModel = action.payload;
    },
    setTargetId:(
      state,action:PayloadAction<any>)  =>  {
        state.targetId=action.payload}
  },
});

export const {
  setModels,
  setFilteredModels,
  setModelHierarchy,
  updateSelectedModel,
  setTargetId
} = assetModelSlice.actions;
export default assetModelSlice.reducer;

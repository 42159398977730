import {
  Binary,
  Boolean,
  Byte,
  DateTime,
  Decimal,
  Double,
  Embedded,
  Float,
  Integer,
  Link,
  List,
  Long,
  Map,
  Short,
  String
} from "../../config/default.config";

//setting property type for addinameConstaintng property
export let propertyType = 0;

export const setType = (type: string) => {
  if (type === Boolean) {
    propertyType = 0;
  } else if (type === Integer) {
    propertyType = 1;
  } else if (type === Short) {
    propertyType = 2;
  } else if (type === Long) {
    propertyType = 3;
  } else if (type === Float) {
    propertyType = 4;
  } else if (type === Double) {
    propertyType = 5;
  } else if (type === DateTime) {
    propertyType = 6;
  } else if (type === String) {
    propertyType = 7;
  } else if (type === Binary) {
    propertyType = 8;
  } else if (type === Embedded) {
    propertyType = 9;
  } else if (type === List) {
    propertyType = 10;
  } else if (type === Map) {
    propertyType = 11;
  } else if (type === Link) {
    propertyType = 12;
  } else if (type === Byte) {
    propertyType = 13;
  } else if (type === Decimal) {
    propertyType = 14;
  }
};

/**Validation Error Messages */
export const NAMING_CONVENTION_ERROR_MESSAGE =
  "The name should only contain alphanumeric characters and underscores";
export const NAMING_CONVENSION_FOR_MODEL =
  "The name should start with characters";
export const NAMING_LIMIT_ERROR_MESSAGE =
  "The name should only contain character limit 3 to 20";

export const NUMERIC_CONVENTION_ERROR_MESSAGE = "Only numbers are allowed";

export const ALPHABETIC_CONVENTION_ERROR_MESSAGE = "Only alphabets are allowed";

export const FLOAT_CONVENTION_ERROR_MESSAGE = "Only float values are allowed";

export const MINVALUE_MAXVALUE_CONVENTION_ERROR =
  "Max length should be greater than min length";



export const REGEX_CONVENTION_ERROR_MESSAGE = "Regular expression is required";

export const MAX_FILE_SIZE_ERROR_MESSAGE = "Large in size";

export const MIN_FILE_SIZE_ERROR_MESSAGE = "Small in size";
export const FILE_TYPE_ERROR_MESSAGE = "File format not supported";

export const VALUE_LIMIT_ERROR_MESSAGE = "The value should be between min and max value"
export const STRING_LIMIT_ERROR_MESSAGE = "The number of character should be below 120"


export const RELATION_ERROR = "Relationship cannot be created between same model";



import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import {
  STATIC_PROPERTY_MESSAGES,
  staticPropertyValidation,
} from "../static-property-validation";

function TypeComponent({
  getValues,
  register,
  setError,
  setValue,
  handleTypeChange,
  errorWrap,
  errors,
  staticPropertyDetails,
}: {
  getValues: any;
  register: any;
  setError: any;
  setValue: any;
  handleTypeChange: (type: string) => void;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
  staticPropertyDetails: IStaticPropertyDetails | undefined;
}) {
  const staticProperties = useAppSelector(
    (state) => state.modelProperties.properties
  );
  const dispatch = useDispatch()

  return (
    <>
      <label className="form-label" htmlFor="PropertyType">
        Type
      </label>
      <select
        className="form-select"
        {...register("type", {
          required: STATIC_PROPERTY_MESSAGES.TYPE_REQUIRED,
        })}
        onChange={(e) => {
          handleTypeChange(e.target.value);
          dispatch(setIsDirty(true))

          if (e.target.value === "DATETIME") {
            setValue("defaultValue", "Select");
            console.log(staticProperties);
            dispatch(setIsDirty(true))

          }
        }}
        disabled={staticPropertyDetails?.isReserved}
      >
        <option value={staticPropertyValidation.Types.BOOLEAN.value}>
          {staticPropertyValidation.Types.BOOLEAN.label}
        </option>
        <option value={staticPropertyValidation.Types.INTEGER.value}>
          {staticPropertyValidation.Types.INTEGER.label}
        </option>
        <option value={staticPropertyValidation.Types.SHORT.value}>
          {staticPropertyValidation.Types.SHORT.label}
        </option>
        <option value={staticPropertyValidation.Types.LONG.value}>
          {staticPropertyValidation.Types.LONG.label}
        </option>
        <option value={staticPropertyValidation.Types.FLOAT.value}>
          {staticPropertyValidation.Types.FLOAT.label}
        </option>
        <option value={staticPropertyValidation.Types.DOUBLE.value}>
          {staticPropertyValidation.Types.DOUBLE.label}
        </option>
        <option value={staticPropertyValidation.Types.STRING.value}>
          {staticPropertyValidation.Types.STRING.label}
        </option>
        <option value={staticPropertyValidation.Types.ICON.value}>
          {staticPropertyValidation.Types.ICON.label}
        </option>
        <option value={staticPropertyValidation.Types.MIMICS.value}>
          {staticPropertyValidation.Types.MIMICS.label}
        </option>
        <option value={staticPropertyValidation.Types.DOCUMENT.value}>
          {staticPropertyValidation.Types.DOCUMENT.label}
        </option>
        <option value={staticPropertyValidation.Types.IMAGE.value}>
          {staticPropertyValidation.Types.IMAGE.label}
        </option>
        <option value={staticPropertyValidation.Types.DATETIME.value}>
          {staticPropertyValidation.Types.DATETIME.label}
        </option>
      </select>
      <>{errorWrap(errors?.type?.message)}</>
    </>
  );
}

export default TypeComponent;

import {
  NAMING_CONVENTION_ERROR_MESSAGE,
  RELATION_ERROR
} from "assets/commons/commons";
import { useAppSelector } from "store";
import "./create-relation.scss";

function CreateRelationshipScreen({
  selector,
  register,
  errors,
  setValue,
  clearErrors,
  createRelation,
}: {
  selector: any;
  register: any;
  errors: any;
  setValue: any;
  clearErrors: any;
  createRelation: boolean;
}) {
  const models = useAppSelector((state) => state.assetModels.models);

  let targetName: any;

  models.forEach(function (target) {
    if (target.id === selector) {
      targetName = target.name;
    }
  });

  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  return (
    <>
      {/* {createRelation ? ( */}
      <div className="seqrops-create-relationship-form-wrapper">
        <div className="container-fluid">
          <div className="seqrops-create-control-form-section">
            <form>
              <div className="row d-flex align-items-center">
                <div className="col-md-6 px-0">
                  <div className="col-md-12 ">
                    <label className="form-label" htmlFor="relationName">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="relationName"
                      minLength={3}
                      maxLength={50}
                      {...register("relationshipName", {
                        required: "Please enter relationship name",
                        pattern: {
                          value: /^\w*$/,
                          message: NAMING_CONVENTION_ERROR_MESSAGE,
                        },
                        minLength: {
                          value: 3,
                          message:
                            "Relation name should contain minimum 3 characters",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "Relation name should contain only maximum 50 characters",
                        },
                      })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(
                          "relationshipName",
                          e.target.value.replace(/\W/g, "")
                        );
                        clearErrors("relationshipName");
                      }}
                    />
                    <label className="form-label" htmlFor="name">
                      {errorWrap(errors?.relationshipName?.message)}
                    </label>
                  </div>
                  <div className="col-md-6  d-inline-block pe-1 ">
                    <label className="form-label" htmlFor="min">
                      Min
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="min"
                      {...register("min", {
                        required: "Please enter minimum cardinality",
                        pattern: {
                          value: /^\d*$/,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    <label className="form-label" htmlFor="min">
                      {errorWrap(errors?.min?.message)}
                    </label>
                  </div>

                  <div className="col-md-6  d-inline-block ps-1 ">
                    <label className="form-label" htmlFor="max">
                      Max
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="max"
                      {...register("max", {
                        required: "Please enter maximum cardinality",
                        pattern: {
                          value: /^\d*$/,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    <label className="form-label" htmlFor="max">
                      {errorWrap(errors?.max?.message)}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 ">
                    <label className="form-label" htmlFor="type">
                      Target type
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="max"
                      {...register("targetId")}
                      onChange={(e: any) => {
                        setValue("targetId", e.target.value);
                        clearErrors("targetId");
                      }}
                      value={targetName}
                      disabled
                    />
                    <label className="form-label">
                      {" "}
                      {errorWrap(errors?.targetId?.message)}
                      {createRelation && <>{errorWrap(RELATION_ERROR)}</>}
                    </label>
                  </div>
                  <div className="col-md-12 ">
                    {/* <input
                      type="checkbox"
                      className="round-checkbox"
                      id="isAssoc"
                      {...register("isAssoc")}
                    />
                    <label htmlFor="isAssoc" className="mx-2">
                      Set as Association
                    </label> */}

                    <label className="form-label">Relation Type</label>
                    <select
                      className="form-select"
                      id="isAssoc"
                      {...register("isAssoc")}
                      onChange={(e: any) => {
                        setValue("isAssoc", e.target.value);
                        clearErrors("isAssoc");
                      }}
                    >
                      <option value="false">Set as Aggregation</option>
                      <option value="true">Set as Association</option>
                    </select>
                    {errorWrap(errors?.isAssoc?.message)}
                    <label className="form-label"></label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ) : (
        <div className="error">
          Relationship cannot be created between same models
        </div>
      )} */}
    </>
  );
}

export default CreateRelationshipScreen;

import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "react-bootstrap";
import { useDialog } from "./DialogProvider";

function ConfirmationDialog() {
  const [openDialog, closeDialog] = useDialog();
  const onOpenDialog = ({ handleClose }: { handleClose: () => void }) => {
    openDialog({
      children: (
        <>
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            You have unsaved changes. Are you sure you want to continue without
            saving these changes?
          </DialogContent>
          <DialogActions>
            <Button
              className="seqrops-btn btn-outline"
              color="primary"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              className="seqrops-btn btn-fill"
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  return {
    onOpenDialog,
    closeDialog,
  };
}

export default ConfirmationDialog;

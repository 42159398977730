import { Tooltip } from "@mui/material";
import collapseClosedIcon from "assets/images/collapse-closed.png";
import collapseOpenedIcon from "assets/images/collapse-opened.png";
import downloadIcon from "assets/images/file_download.svg";
import deleteIcon from "assets/images/seqrops_delete_icon.svg";
import editIcon from "assets/images/seqrops_edit_icon.svg";
import noDataFoundIcon from "assets/images/seqrops_no-search-results_icon.svg";
import notRequiredIcon from "assets/images/seqrops_not_required.svg";
import requiredIcon from "assets/images/seqrops_required.svg";
import viewIcon from "assets/images/seqrops_view_icon.svg";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { staticPropertyValidation } from "components/static-property/static-property-validation";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { modelPropertyActions } from "store/model-properties/model-property.action";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { staticPropertyActions } from "store/model-properties/static-property.action";
import "./static-property-table.scss";
const filetypes = [
  staticPropertyValidation.Types.MIMICS.value,
  staticPropertyValidation.Types.ICON.value,
  staticPropertyValidation.Types.DOCUMENT.value,
  staticPropertyValidation.Types.IMAGE.value,
];
function StaticPropertyTable({
  showPropertyDetails,
  staticPropertyDetails,
  modelId,
}: {
  showPropertyDetails: (details: IStaticPropertyDetails | undefined) => void;
  modelId: number | undefined;
  staticPropertyDetails: IStaticPropertyDetails | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatedOpen, setIsCreatedOpen] = useState(true);

  const dispatch = useAppDispatch();
  const confirmationPopup = DeleteConfirmationDialog();

  const staticProperties = useAppSelector(
    (state) => state.modelProperties.properties
  );
  const handleEdit = async (propertyId: number) => {
    const propertyDetails =
      await modelPropertyActions.getStaticPropertyDetailsById(propertyId);
    showPropertyDetails(propertyDetails);
  };
  const handleView = async (propertyId: number) => {
    const propertyDetails =
      await modelPropertyActions.getStaticPropertyDetailsById(propertyId);
    showPropertyDetails(propertyDetails);
  };

  const handleDelete = async (
    propertyId: number,
    propertyType: string,
    reference: string,
    downloadUrl: string
  ) => {
    const propertyDetails = await staticPropertyActions.deleteStaticProperty(
      propertyId,
      reference,
      filetypes.includes(propertyType) && downloadUrl ? true : false
    );

    if (propertyDetails && modelId) {
      confirmationPopup.closeDialog();
      dispatch(staticPropertyActions.getModelStaticProperties(modelId));
    }
  };

  if (staticProperties.length === 0) {
    return (
      <>
        <div className="seqrops-no-data-found-section-wrapper">
          <div className="seqrops-no-data-found-section">
            <img
              src={noDataFoundIcon}
              className="img-fluid"
              alt="seqrops no data found icon"
            />
            <p>No data found</p>
          </div>
        </div>
      </>
    );
  }

  const created_property_length = staticProperties.filter(property => property.isReserved === null).length

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsCreatedOpen(false);

  };

  const toggleCreatedOpen = () => {
    setIsCreatedOpen(!isCreatedOpen);
    setIsOpen(false)
  };
  return (
    <>

      <div>
        <span className="seqrops-default-property-inner-console-section d_table_collapse d-flex " onClick={toggleOpen}>
          <div className="seqrops-property-title">
            <h3>System Properties</h3>
          </div>
          {isOpen ? (
            <>
              <span >
                <img
                  src={collapseClosedIcon}
                  width={25}
                  height={25}
                  alt={"Close"}
                />
              </span>
            </>
          ) : (
            <>
              <span
                onClick={() => {
                }}
              >
                <img
                  src={collapseOpenedIcon}
                  width={25}
                  height={25}
                  alt={"Open"}
                />
              </span>
            </>
          )}
        </span>


        {isOpen && (<div className="seqrops-property-table-wrapper">
          <div className="container-fluid mt-2" >
            <div className="seqrops-property-table-section">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Required</th>
                    <th>Constraints</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {staticProperties.filter(property => property.isReserved === true && property.required === true)
                    .map((property: IStaticPropertyDetails) => {

                      let showDownload = false;
                      let downloadUrl = (property.defaultFile as string) || "";
                      if (filetypes.includes(property.type)) {
                        showDownload = true;
                      }
                      return (
                        <tr
                          key={property.id}
                          className="seqrops-property-table-content-section"
                        >
                          <td>{property.name}</td>
                          {property.type === "DATETIME" ? (
                            <td>{"DATE & TIME"}</td>
                          ) : (
                            <td>{property.type}</td>
                          )}

                          <td className="seqrops-property-table-required-column">
                            {property.required && (
                              <img src={requiredIcon} alt="seqrops required icon" />
                            )}
                            {!property.required && (
                              <img
                                src={notRequiredIcon}
                                alt="seqrops not required icon"
                              />
                            )}
                          </td>
                          <td
                            width="200"
                            className="seqrops-property-table-constraints-column"
                          >
                            {!showDownload

                              &&
                              !(property.type === "BOOLEAN") &&
                              !(property.type === "DATETIME") ? (
                              <div className="constraints-data-section d-flex align-items-center">
                                <div className="title-section">
                                  <p>Min &nbsp;</p>
                                </div>
                                {property.minLength ? (
                                  <div className="value-section">
                                    {property.minLength}&nbsp;
                                  </div>
                                ) : (
                                  <div className="value-section">0&nbsp;</div>
                                )}
                                <div className="title-section">
                                  <p>Max &nbsp;</p>
                                </div>
                                <div className="value-section">
                                  {property.maxLength ? (
                                    <> {property.maxLength}</>
                                  ) : (
                                    <>0 </>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </td>

                          <td width="90" className="d-flex seqrops-button-section">
                            {property.isReserved ? (
                              <div>
                                <button
                                  onClick={() => {
                                    handleView(property.id);
                                  }}
                                >
                                  <Tooltip title="View">
                                    <img src={viewIcon} style={{ width: "15px" }} alt="seqrops view button" />
                                  </Tooltip>
                                </button>
                              </div>
                            ) : (
                              <div style={{ marginTop: "5px", display: "flex" }}>
                                <button
                                  onClick={() => {
                                    confirmationPopup.onOpenDialog({
                                      title: "Delete Confirmation",
                                      message:
                                        "Are you sure want to delete the property?",
                                      cancelBtn: "Cancel",
                                      successBtn: "Delete",
                                      handleClose: () => {
                                        handleDelete(
                                          property.id,
                                          property.type,
                                          property.reference,
                                          downloadUrl
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <img

                                      src={deleteIcon}
                                      alt="seqrops edit button"
                                    />
                                  </Tooltip>
                                </button>
                                <button
                                  onClick={() => {
                                    handleEdit(property.id);
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <img src={editIcon} alt="seqrops edit button" />
                                  </Tooltip>
                                </button>
                                <button className="download_sec">
                                  {showDownload && downloadUrl ? (
                                    <a
                                      href={downloadUrl}
                                      title={property.name}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      <Tooltip title="Download">
                                        <img
                                          src={downloadIcon}
                                          alt="seqrops edit button"
                                          className="img-fluid seqrops-edit-button"
                                        />
                                      </Tooltip>
                                    </a>
                                  ) : null}
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        )}

        <span className="seqrops-default-property-inner-console-section d_table_collapse d-flex mt-3" onClick={toggleCreatedOpen}>
          <div className="seqrops-property-title">
            <h3>Properties</h3>
          </div>
          {isCreatedOpen ? (
            <>
              <span >
                <img
                  src={collapseClosedIcon}
                  width={25}
                  height={25}
                  alt={"Close"}
                />
              </span>
            </>
          ) : (
            <>
              <span
                onClick={() => {
                  console.log("opened");
                }}
              >
                <img
                  src={collapseOpenedIcon}
                  width={25}
                  height={25}
                  alt={"Open"}
                />
              </span>
            </>
          )}
        </span>

        {isCreatedOpen && created_property_length > 0 && (<div className="seqrops-property-table-wrapper">
          <div className="container-fluid mt-2" >
            <div className="seqrops-property-table-section">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Required</th>
                    <th>Constraints</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {staticProperties.filter(property => property.isReserved === null)
                    .map((property: IStaticPropertyDetails) => {

                      let showDownload = false;
                      let downloadUrl = (property.defaultFile as string) || "";
                      if (filetypes.includes(property.type)) {
                        showDownload = true;
                      }
                      return (
                        <tr
                          key={property.id}
                          className="seqrops-property-table-content-section"
                        >
                          <td>{property.name}</td>
                          {property.type === "DATETIME" ? (
                            <td>{"DATE & TIME"}</td>
                          ) : (
                            <td>{property.type}</td>
                          )}

                          <td className="seqrops-property-table-required-column">
                            {property.required && (
                              <img src={requiredIcon} alt="seqrops required icon" />
                            )}
                            {!property.required && (
                              <img
                                src={notRequiredIcon}
                                alt="seqrops not required icon"
                              />
                            )}
                          </td>
                          <td
                            width="200"
                            className="seqrops-property-table-constraints-column"
                          >
                            {!showDownload

                              &&
                              !(property.type === "BOOLEAN") &&
                              !(property.type === "DATETIME") ? (
                              <div className="constraints-data-section d-flex align-items-center">
                                <div className="title-section">
                                  <p>Min  &nbsp;</p>
                                </div>
                                {property.minLength ? (
                                  <div className="value-section">
                                    {property.minLength}&nbsp;
                                  </div>
                                ) : (
                                  <div className="value-section">0&nbsp;</div>
                                )}
                                <div className="title-section">
                                  <p>Max &nbsp;</p>
                                </div>
                                <div className="value-section">
                                  {property.maxLength ? (
                                    <> {property.maxLength}</>
                                  ) : (
                                    <>0 </>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </td>

                          <td width="90" className="d-flex seqrops-button-section">
                            {property.isReserved ? (
                              <div>
                                <button
                                  onClick={() => {
                                    handleView(property.id);
                                  }}
                                >
                                  <Tooltip title="View">
                                    <img src={viewIcon} style={{ width: "15px" }} alt="seqrops view button" />
                                  </Tooltip>
                                </button>
                              </div>
                            ) : (
                              <div style={{ marginTop: "5px", display: "flex" }}>
                                <button
                                  onClick={() => {
                                    confirmationPopup.onOpenDialog({
                                      title: "Delete Confirmation",
                                      message:
                                        "Are you sure want to delete the property?",
                                      cancelBtn: "Cancel",
                                      successBtn: "Delete",
                                      handleClose: () => {
                                        handleDelete(
                                          property.id,
                                          property.type,
                                          property.reference,
                                          downloadUrl
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <img

                                      src={deleteIcon}
                                      alt="seqrops edit button"
                                    />
                                  </Tooltip>
                                </button>
                                <button
                                  onClick={() => {
                                    handleEdit(property.id);
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <img src={editIcon} alt="seqrops edit button" />
                                  </Tooltip>
                                </button>
                                <button className="download_sec">
                                  {showDownload && downloadUrl ? (
                                    <a
                                      href={downloadUrl}
                                      title={property.name}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      <Tooltip title="Download">
                                        <img
                                          src={downloadIcon}
                                          alt="seqrops edit button"
                                          className="img-fluid seqrops-edit-button"
                                        />
                                      </Tooltip>
                                    </a>
                                  ) : null}
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        )}
        {isCreatedOpen && created_property_length === 0 &&
          (< div className="seqrops-no-data-found-section-wrapper">
            <div className="seqrops-no-data-found-section">
              <img
                src={noDataFoundIcon}
                className="img-fluid"
                alt="seqrops no data found icon"
              />
              <p>No data found</p>
            </div>
          </div>)}
      </div>
    </>
  );
}

export default StaticPropertyTable;

import nodeOptionsIcon from "assets/images/seqrops_node_options.svg";
import React from "react";

const NodeToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <span
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img src={nodeOptionsIcon} alt="seqrops node options" />
  </span>
));

export default NodeToggle;

import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SeqrOpsLTR from "./containers/default-container.layout/default-container.layout";

import DialogProvider from "components/dialogs/DialogProvider";
import { ASSET_SUITE_LOGIN_URL, IFRAME_ID, KEYCLOAK_GRANT_TYPES, SESSION_KEY, SESSION_TYPE } from "config/default.config";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "store";
import { validateToken } from "store/keycloak/asset-keycloak.action";
import { ITokenDetails, IValidateTokenPayload } from "store/keycloak/asset-keycloak.interface";
import { setLogin } from "store/seqr-ops/seqr-ops.slice";
import { encryptUtils } from "utils/encryption.utils";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { assetModelActions } from "./store/asset-model/asset-model.action";

function App() {
  const [ready, setReady] = useState<boolean>(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [modelsCount, setModelsCount] = useState<number>(0);
  const models = useAppSelector((state) => state.assetModels.rawModels);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);

  useEffect(() => {
    console.log("loading", loading);

    async function fetchData() {
      const count = await assetModelActions.getAllModelsCount();
      setModelsCount(count);
      setReady(true);
    }
    if (isLogged) {
      fetchData();
    }
  }, [models, isLogged]);

  useEffect(() => {
    console.log("loading", loading);

    let tokenDataEncrypted: any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if (tokenData !== null) {
      let data = tokenData;
      const ITokenDetails: ITokenDetails = {
        auth_token: data.token?.auth_token,
        refresh_token: data.token?.refresh_token,
        client_id: data.token?.client_id,
        client_secret: data.token?.client_secret,
        username: data.token?.username,
        password: data.token?.password,
      }
      const IValidateTokenPayload: IValidateTokenPayload = {
        token: data.token?.auth_token,
        client_id: data.token?.client_id,
        client_secret: data.token?.client_secret,
        username: data.token?.username,
        password: data.token?.password,
        grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD
      }
      dispatch(setLogin(data?.token?.isLoggedIn))
      dispatch(validateToken(IValidateTokenPayload, data.token?.refresh_token, ITokenDetails));
      setIsLogged(true);
    } else {
      let rl = encryptUtils.encryptURL(SESSION_KEY.MODELS_KEY);
      let postMsg = { [SESSION_KEY.REDIRECT_LOCATION_KEY]: rl, type: SESSION_TYPE.USER_LOGIN_TYPE };
      const POST_DOMAIN_DATA = [
        {
          frameId: IFRAME_ID.SUITE_IFRAME_ID,
          url: `${process.env.REACT_APP_SUITE_URL}`,
        }
      ]
      for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
        console.log("cross domain test in qa models ::: ", POST_DOMAIN_DATA[i]);
        const iframe = document.getElementById(
          POST_DOMAIN_DATA[i].frameId
        ) as HTMLIFrameElement;
        iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
      }
      window.location.replace(ASSET_SUITE_LOGIN_URL);
    }
  }, [loading])

  if (!ready) {
    return null;
  }

  return (
    <div className="App">
      <DialogProvider>
        <Routes>
          <Route
            path="/"
            element={<SeqrOpsLTR modelsCount={modelsCount} />}
          ></Route>
        </Routes>
      </DialogProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ?
        <div className="loader-row">
          <div className="loader">
            <div id="ld4">
              <div>

              </div>
              <div>
              </div>
              <div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div> : null}
    </div>
  );
}

export default App;

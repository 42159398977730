import assosiationIcon from "assets/images/assosiation_icon.svg";
import collapseClosedIcon from "assets/images/collapse-closed.png";
import collapseOpenedIcon from "assets/images/collapse-opened.png";
import aggrigationIcon from "assets/images/seqrops_relation_Icon.svg";
import submodelIcon from "assets/images/seqrops_sub_model_icon.svg";
import { useEffect, useState } from "react";
import { IModelTreeItem } from "../model-tree.types";
import NodeActionMenu from "./node-action-menu";
import TreeList from "./tree-list";

function AddItem({ parent, funcs }: any) {
  return (
    <span style={{ marginLeft: 10 }} onClick={() => funcs.addChild(parent)}>
      <b>ADD_ICON</b>
    </span>
  );
}
function TreeItem({
  customKey,
  item,
  actions,
  toggleMap,
  actionMap,
}: IModelTreeItem) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {

    if (!expanded && customKey === "tree-root-0") {
      toggleOpen(customKey);
      setExpanded(true);
    }
  }, []);


  const isTreeTopNode = customKey === "tree-root-0" ? true : false;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  //handling double click
  const handleDoubleClick = () => {
    setIsEdit(true);
    window.addEventListener("keydown", handleEsc);
    window.addEventListener("focusout", handleFocusOut);
  };

  //handling focus out
  const handleFocusOut = () => {
    setIsEdit(false);
  };

  //handling esc keypress
  const handleEsc = (event: any) => {
    if (event.keyCode === 27) {
      setIsEdit(false);
    }
  };

  const { toggleOpen, getSubModel } = actions;
  return (
    <li className="ps-0 pt-2" style={{ listStyle: "none" }}>
      <span style={{ border: "1px solid #e0dada" }} className="d_tree_main">
        {item.hasEdge && !item.isAssociation && (
          <span
            className="d_tree_collapse"
            style={{ marginRight: 5 }}
            onClick={() => toggleOpen(customKey)}
          >
            {toggleMap[customKey] ? (
              <>
                <span>
                  <img
                    src={collapseClosedIcon}
                    width={25}
                    height={25}
                    alt={"Close"}
                  />
                </span>
              </>
            ) : (
              <>
                <span
                  onClick={() => {
                    if (
                      item.hasEdge &&
                      item.edges.length === 0 &&
                      !item.isAssociation
                    ) {
                      getSubModel(item.id, customKey);
                    }
                  }}
                >
                  <img
                    src={collapseOpenedIcon}
                    width={25}
                    height={25}
                    alt={"Open"}
                  />
                </span>
              </>
            )}
          </span>
        )}

        <span className="d_tree_inner w-100" style={{ color: "green" }}>
          {item.isEdge ? (
            <span>
              {item.edges.length !== 0 ?
                <>
                  <img
                    src={aggrigationIcon}
                    width={25}
                    height={25}
                    alt={item.edgeName}
                  />
                </> :
                <>
                  <img
                    src={assosiationIcon}
                    width={25}
                    height={25}
                    alt={item.edgeName}
                  />
                </>}
              <label htmlFor="" className="tree-label">

                {item.edgeName}
              </label>
              <NodeActionMenu
                isEdge={item.isEdge}
                id={item.id}
                name={item.edgeName}
                isRootNode={false}
                description={item.description}
                isEquipment={item.isEquipment}
                icon={item.icon}
                actions={actions}
              />
            </span>
          ) : (
            <span
              onDoubleClick={() => {
                handleDoubleClick();
              }}
            >
              <img
                src={submodelIcon}
                width={25}
                height={25}
                alt={item.vertexName}
              />
              {isEdit ? (
                <>
                  <input
                    type="text"
                    className="seqrops-node-name-edit-section"
                    defaultValue={item.vertexName}
                    autoFocus
                  />
                </>
              ) : (
                item.vertexName
              )}
              {isTreeTopNode ? (

                <NodeActionMenu
                  isEdge={false}
                  id={item.id}
                  name={item.vertexName}
                  isRootNode={item.isRootNode}
                  description={item.description}
                  isEquipment={item.isEquipment}
                  icon={item.icon}
                  actions={actions}
                />
              ) : null}
            </span>
          )}
        </span>

        {item.isEdge && item.edges.length < item.max ? (
          <AddItem parent={item} actions={actions} />
        ) : null}
      </span>
      {item.hasEdge && toggleMap[customKey] && (
        <TreeList
          customKey={customKey}
          tree={item.edges}
          actions={actions}
          toggleMap={toggleMap}
          actionMap={actionMap}
        />
      )}
    </li>
  );
}

export default TreeItem;

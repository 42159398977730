import warning from "assets/images/warning_icon.svg";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import "./delete-relation.scss";

interface IDeleteRelationScreenProps {
  showModel: boolean;
  setShowModel: (show: boolean) => void;
  id: number;
  name: string;
  sourceId: number;
}

function DeleteRelationScreen({
  showModel,
  setShowModel,
  id,
  name,
  sourceId,
}: IDeleteRelationScreenProps) {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setShowModel(false);
  };

  const setSuccess = (state: boolean) => {
    setShowModel(false);
  };

  const handleConfirm = () => {
    dispatch(assetModelActions.deleteRelation(id, sourceId, setSuccess));
  };

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="delete_model">
            <div className="d-flex align-items-center">
            <img className="warning" src={warning} alt="" /> 
            <div>
            Are you sure you
            want to delete relation <b>{name}</b> ?</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="seqrops-btn btn-outline me-2"
              onClick={handleClose}
            >
              No
            </Button>
            <Button className="seqrops-btn btn-fill" onClick={handleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default DeleteRelationScreen;

import { useEffect } from "react";
import "./alarm-form.scss";

function AlarmForm({ register, values, setValue, errors, clearErrors }: any) {

  useEffect(() => {
    if (errors.hasOwnProperty('alarms')) {
      const error_type = Object.keys(errors['alarms']?.analog)[0]
      const error_element = document.getElementById('error_' + error_type);
      error_element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors])


  const errorWrap = (errorMsg: string) => {
    console.log(errorMsg);

    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const clearAlarmError = () => {
    if (errors?.alarms?.analog?.HIHI?.value?.message) {
      clearErrors("alarms.analog.HIHI.value");
    }
    if (errors?.alarms?.analog?.HI?.value?.message) {
      clearErrors("alarms.analog.HI.value");
    }
    if (errors?.alarms?.analog?.LOW?.value?.message) {
      clearErrors("alarms.analog.LOW.value");
    }
    if (errors?.alarms?.analog?.LOWLOW?.value?.message) {
      clearErrors("alarms.analog.LOWLOW.value");
    }
    if ("Please select normal value") {
      console.log("in if");

      clearErrors("alarms.digital.mode");
    }
  };
  return (
    <>
      <div className="seqrops-alarm-form-wrapper">
        <div className="">
          <div className="seqrops-basic-form-head-section">
            <p>Alarm</p>
          </div>

          {values?.type === "DIGITAL" && (
            <div className="seqrops-alarm-form-section">
              <div className="row col-md-12">
                <div>
                  <label className="form-label" htmlFor="normal">
                    Normal
                  </label>
                  <select

                    {...register("alarms.digital.mode", {
                      required: "Please select normal value",
                    })}
                    className="form-select"
                    onChange={(e) => {
                      clearAlarmError();

                    }}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </select>
                </div>
                {errorWrap(
                  errors?.alarms?.digital?.mode?.message || "" || ""
                )}
                <div className="col-md-12">
                  <label className="form-label" htmlFor="normalMessage">
                    Normal Message
                  </label>
                  <input
                    className="form-control"
                    {...register("alarms.digital.normal", {})}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label" htmlFor="abNormalMessage">
                    Abnormal Message
                  </label>
                  <input
                    className="form-control"
                    {...register("alarms.digital.abnormal", {})}
                  />
                </div>
              </div>
            </div>
          )}
          {values?.type !== "DIGITAL" && values?.type !== "" && (
            <div className="seqrops-alarm-form-section">
              <div className="row col-md-12">
                <div className="seqrops-alarm-item d-flex justify">
                  <input
                    className="me-2"
                    type="checkbox"
                    {...register("alarms.analog.HIHI.checked", {})}
                    onChange={(e) => {
                      setValue("alarms.analog.HIHI.checked", e.target.checked, {
                        shouldValidate: true,
                      });
                      clearAlarmError();
                    }}
                  />
                  <span>HIHI</span>
                </div>
                {values.alarms?.analog.HIHI.checked && (
                  <>
                    <div className="alarm-value d-flex flex-column">
                      <div className="" id="error_HIHI">
                        <label className="form-label" htmlFor="value">
                          Value
                        </label>
                        <input
                          className="me-2 form-control"
                          type="number"
                          id="value"
                          {...register("alarms.analog.HIHI.value", {
                            required: "Please enter value",
                          })}
                          onChange={(e) => {
                            if (errors?.alarms?.analog?.HIHI) {
                              clearErrors("alarms.analog.HIHI.value");
                            }
                            setValue(e.target.value, { shouldValidate: true });
                          }}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.HIHI?.value?.message || "" || ""
                        )}
                      </div>
                      <div className="">
                        <label className="form-label" htmlFor="message">
                          Message
                        </label>
                        <input
                          className="me-2 form-control"
                          type="text"
                          id="message"
                          {...register("alarms.analog.HIHI.message", {
                            required: "Please enter message",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.HIHI?.message?.message || ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row col-md-12 alarm-type">
                <div className="seqrops-alarm-item d-flex justify">
                  <input
                    className="me-2"
                    type="checkbox"
                    {...register("alarms.analog.HI.checked", {})}
                    onChange={(e) => {
                      setValue("alarms.analog.HI.checked", e.target.checked, {
                        shouldValidate: true,
                      });
                      clearAlarmError();
                    }}
                  />
                  <span>HI</span>
                </div>
                {values.alarms?.analog.HI.checked && (
                  <>
                    <div className="alarm-value d-flex flex-column">
                      <div className="" id="error_HI">
                        <label className="form-label" htmlFor="value">
                          Value
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="value"
                          {...register("alarms.analog.HI.value", {
                            required: "Please enter value",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.HI?.value?.message || "" || ""
                        )}
                      </div>
                      <div className="">
                        <label className="form-label" htmlFor="message">
                          Message
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="message"
                          {...register("alarms.analog.HI.message", {
                            required: "Please enter message",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.HI?.message?.message || ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row col-md-12 alarm-type">
                <div className="seqrops-alarm-item d-flex justify">
                  <input
                    className="me-2"
                    type="checkbox"
                    {...register("alarms.analog.LOW.checked", {})}
                    onChange={(e) => {
                      setValue("alarms.analog.LOW.checked", e.target.checked, {
                        shouldValidate: true,
                      });
                      clearAlarmError();
                    }}
                  />
                  <span>LOW</span>
                </div>
                {values.alarms?.analog.LOW.checked && (
                  <>
                    <div className="alarm-value d-flex flex-column">
                      <div className="" id="error_LOW" >
                        <label className="form-label" htmlFor="value">
                          Value
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          {...register("alarms.analog.LOW.value", {
                            required: "Please enter value",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.LOW?.value?.message || "" || ""
                        )}
                      </div>
                      <div className="">
                        <label className="form-label" htmlFor="message">
                          Message
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="message"
                          {...register("alarms.analog.LOW.message", {
                            required: "Please enter message",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.LOW?.message?.message || ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row col-md-12 alarm-type">
                <div className="seqrops-alarm-item d-flex justify">
                  <input
                    className="me-2"
                    type="checkbox"
                    {...register("alarms.analog.LOWLOW.checked", {})}
                    onChange={(e) => {
                      setValue(
                        "alarms.analog.LOWLOW.checked",
                        e.target.checked,
                        {
                          shouldValidate: true,
                        }
                      );
                      clearAlarmError();
                    }}
                  />
                  <span>LOWLOW</span>
                </div>
                {values.alarms?.analog.LOWLOW.checked && (
                  <>
                    <div className="alarm-value d-flex flex-column">
                      <div className="" id="error_LOWLOW">
                        <label className="form-label" htmlFor="value">
                          Value
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="value"
                          {...register("alarms.analog.LOWLOW.value", {
                            required: "Please enter value",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.LOWLOW?.value?.message || "" || ""
                        )}
                      </div>
                      <div className="">
                        <label className="form-label" htmlFor="message">
                          Message
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="message"
                          {...register("alarms.analog.LOWLOW.message", {
                            required: "Please enter message",
                          })}
                        />
                        {errorWrap(
                          errors?.alarms?.analog?.LOWLOW?.message?.message || ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AlarmForm;

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { interceptiorUtils } from "utils/interceptor.utils";
import assetModelSlice from "./asset-model/asset-model.slice";
import assetKeycloakSlice from "./keycloak/asset-keycloak.slice";
import modelPropertySlice from "./model-properties/model-property.slice";
import seqrOpsSlice from "./seqr-ops/seqr-ops.slice";

export const store = configureStore({
  reducer: {
    seqrOps: seqrOpsSlice,
    assetModels: assetModelSlice,
    modelProperties: modelPropertySlice,
    assetKeycloak: assetKeycloakSlice,

  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

interceptiorUtils.initialize();

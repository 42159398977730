import { IAddStaticPropertyReq } from "store/model-properties/model-property.interface";

export interface ITypeFields {
  defaultValue: {
    show: boolean;
    value: any;
  };
  defaultFile: {
    show: boolean;
    value: any;
  };
  required: {
    show: boolean;
    value: any;
  };
  regex: {
    show: boolean;
    value: any;
  };
  min: {
    show: boolean;
    value: any;
  };
  max: {
    show: boolean;
    value: any;
  };
  isReserved: {
    show: boolean;
    value: false;
  };
}

export interface IStaticTypes {
  value: string;
  label: string;
  fields: ITypeFields;
}

export interface IValidateResponse {
  valid: boolean;
  field: string;
  message: string;
  value: any;
}

export const STATIC_PROPERTY_MESSAGES = {
  NAME_REQUIRED: "Please enter name",
  NAME_PATTERN: "Please enter a valid name",
  NAME_MIN_MAX: "The name should only contain character limit 3 to 50",
  TYPE_REQUIRED: "Please select type",
};

const Types: { [key: string]: IStaticTypes } = {
  BOOLEAN: {
    value: "BOOLEAN",
    label: "Boolean",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  INTEGER: {
    value: "INTEGER",
    label: "Integer",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  SHORT: {
    value: "SHORT",
    label: "Short",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  LONG: {
    value: "LONG",
    label: "Long",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  FLOAT: {
    value: "FLOAT",
    label: "Float",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  DOUBLE: {
    value: "DOUBLE",
    label: "Double",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  STRING: {
    value: "STRING",
    label: "String",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: true,
        value: "",
      },
      min: {
        show: true,
        value: "",
      },
      max: {
        show: true,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  ICON: {
    value: "ICON",
    label: "Icon",
    fields: {
      defaultValue: {
        show: false,
        value: "",
      },
      defaultFile: {
        show: true,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  MIMICS: {
    value: "MIMICS",
    label: "Mimics",
    fields: {
      defaultValue: {
        show: false,
        value: "",
      },
      defaultFile: {
        show: true,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  DOCUMENT: {
    value: "DOCUMENT",
    label: "Document",
    fields: {
      defaultValue: {
        show: false,
        value: "",
      },
      defaultFile: {
        show: true,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  IMAGE: {
    value: "IMAGE",
    label: "Image",
    fields: {
      defaultValue: {
        show: false,
        value: "",
      },
      defaultFile: {
        show: true,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
  DATETIME: {
    value: "DATETIME",
    label: "Date & Time",
    fields: {
      defaultValue: {
        show: true,
        value: "",
      },
      defaultFile: {
        show: false,
        value: "",
      },
      required: {
        show: true,
        value: "",
      },
      regex: {
        show: false,
        value: "",
      },
      min: {
        show: false,
        value: "",
      },
      max: {
        show: false,
        value: "",
      },
      isReserved: {
        show: false,
        value: false,
      },
    },
  },
};

const filetypes = {
  [Types.MIMICS.value]: ["application/json"],
  [Types.ICON.value]: [
    "image/png",
    "image/gif",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ],
  [Types.DOCUMENT.value]: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/pdf",
    "text/csv",
  ],
  [Types.IMAGE.value]: ["image/png", "image/gif", "image/jpeg", "image/jpg"],
};

const validateMinMax = (
  fields: ITypeFields,
  propertyType: string,
  min: number,
  max: number
): IValidateResponse => {


  let type: "string" | "file" | "number" | undefined = undefined;
  if (propertyType === staticPropertyValidation.Types.STRING.value) {
    type = "string";
  } else if (fields.defaultFile) {
    type = "file";
  } else {
    type = "number";
  }

  let messagePart = "";
  if (type === "string") {
    messagePart = "length";
  } else if (type === "number") {
    messagePart = "value";
  } else {
    messagePart = "size";
  }

  if (min && max || max === 0) {

    if (Number(min) > Number(max) || Number(min) === Number(max)) {


      return {
        valid: false,
        field: "minLength",
        message: `Min ${type === "string" ? "length" : "value"
          } should be less than max ${messagePart}`,
        value: min,
      };
    }
  }

  return {
    valid: true,
    field: "",
    message: "",
    value: "",
  };
};

const validateBoolean = (
  validResponse: IValidateResponse,
  defaultValue: any
): IValidateResponse => {
  const defaultBooleanValue: string = ("" + defaultValue + "").trim();
  if (defaultBooleanValue) {
    if (defaultBooleanValue === "true") {
      return { ...validResponse, value: "true" };
    } else {
      return { ...validResponse, value: "false" };
    }
  } else {
    return validResponse;
  }
};

const validateString = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultStringValue: string = ("" + defaultValue + "").trim();
  if (defaultStringValue) {
    if (min && defaultStringValue.length < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should have minimum " + min + " characters",
        value: defaultStringValue,
      };
    } else if (max && defaultStringValue.length > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should have upto " + max + " characters",
        value: defaultStringValue,
      };
    } else {
      return { ...validResponse, value: defaultStringValue };
    }
  }
  return { ...validResponse, value: defaultStringValue };
};

const validateRegex = (
  validResponse: IValidateResponse,
  defaultValue: any,
  regex: any
): IValidateResponse => {
  const defaultStringValue: string = ("" + defaultValue + "").trim();
  if (defaultStringValue) {
    if (regex.test(defaultStringValue)) {

    }
  }
  return { ...validResponse, value: defaultStringValue };
};
const validateDateTime = (
  validResponse: IValidateResponse,
  defaultValue: any
): IValidateResponse => {
  const defaultDateTimeValue = defaultValue;
  return { ...validResponse, value: defaultDateTimeValue };
};

const validateNumber = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultIntegerValue = defaultValue;

  if (!isNaN(defaultIntegerValue)) {
    if (min && defaultIntegerValue < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be greater than or equal to " + min,
        value: defaultIntegerValue,
      };
    } else if (max && defaultIntegerValue > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be less than or equal to " + max,
        value: defaultIntegerValue,
      };
    } else {
      return { ...validResponse, value: defaultIntegerValue };
    }
  }
  return { ...validResponse, value: defaultIntegerValue };
};

const validateShort = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultShortValue = defaultValue;
  if (!isNaN(defaultShortValue)) {
    if (defaultShortValue < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be greater than or equal to " + min,
        value: defaultShortValue,
      };
    } else if (defaultShortValue > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be less than or equal to " + max,
        value: defaultShortValue,
      };
    } else {
      return { ...validResponse, value: defaultShortValue };
    }
  }
  return { ...validResponse, value: defaultShortValue };
};

const validateLong = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultLongValue = defaultValue;

  if (!isNaN(defaultLongValue)) {
    if (defaultLongValue < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be greater than or equal to " + min,
        value: defaultLongValue,
      };
    } else if (defaultLongValue > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be less than or equal to " + max,
        value: defaultLongValue,
      };
    } else {
      return { ...validResponse, value: defaultLongValue };
    }
  }
  return { ...validResponse, value: defaultLongValue };
};

// const validateShort = (
//   validResponse: IValidateResponse,
//   defaultValue: any,
//   min: number,
//   max: number
// ): IValidateResponse => {
//   const defaultShortValue = defaultValue;

//   console.log("defaultShortValue", defaultShortValue);
//   if (min >= -32768 && max <= 32767) {
//     if (!isNaN(defaultShortValue)) {
//       if(min >= -32768){
//         if (min && defaultShortValue < min) {
//           return {
//             valid: false,
//             field: "defaultValue",
//             message: "Default value should be greater than or equal to " + min,
//             value: defaultShortValue,
//           };
//         }
//       }else{
//         return {
//           valid: false,
//           field: "minLength",
//           message: "Min length should not be less than -2147483648 ",
//           value: defaultShortValue,
//         };
//       }if (min >= -32768 && max <= 32767) {
//          if (max && defaultShortValue > max) {
//           return {
//             valid: false,
//             field: "defaultValue",
//             message: "Default value should be less than or equal to " + max,
//             value: defaultShortValue,
//           };
//         }else{
//           return {
//             valid: false,
//             field: "maxLength",
//             message: "Max length should not be less than -2147483647 ",
//             value: defaultShortValue,
//           };
//         }
//       }
//        else {
//         return { ...validResponse, value: defaultShortValue };
//       }
//     }
//     return { ...validResponse, value: defaultShortValue };
//   }
    
  
// };

const validateFloat = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultFloatValue = defaultValue;

  if (!isNaN(defaultFloatValue)) {
    if (defaultFloatValue < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be greater than or equal to " + min,
        value: defaultFloatValue,
      };
    } else if (defaultFloatValue > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be less than or equal to " + max,
        value: defaultFloatValue,
      };
    } else {
      return { ...validResponse, value: defaultFloatValue };
    }
  }
  return { ...validResponse, value: defaultFloatValue };
};

const validateDouble = (
  validResponse: IValidateResponse,
  defaultValue: any,
  min: number,
  max: number
): IValidateResponse => {
  const defaultDoubleValue = defaultValue;

  if (!isNaN(defaultDoubleValue)) {
    if (defaultDoubleValue < min) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be greater than or equal to " + min,
        value: defaultDoubleValue,
      };
    } else if (defaultDoubleValue > max) {
      return {
        valid: false,
        field: "defaultValue",
        message: "Default value should be less than or equal to " + max,
        value: defaultDoubleValue,
      };
    } else {
      return { ...validResponse, value: defaultDoubleValue };
    }
  }
  return { ...validResponse, value: defaultDoubleValue };
};

const validateFile = (
  validResponse: IValidateResponse,
  propertyType: string,
  defaultFile: FileList | string
): IValidateResponse => {
  if (defaultFile?.length > 0) {
    const file = defaultFile[0] as File;

    if (!filetypes[propertyType].includes(file.type)) {
      return {
        valid: false,
        field: "defaultFile",
        message: "Please select a valid file",
        value: "",
      };
    } else {
      if (propertyType === Types.ICON.value) {
        if (file.size / (1024 * 1024) > 5) {
          return {
            valid: false,
            field: "defaultFile",
            message: "Please select a file less than 5MB",
            value: "",
          };
        }
      } else {
        if (file.size / (1024 * 1024) > 20) {
          return {
            valid: false,
            field: "defaultFile",
            message: "Please select a file less than 20MB",
            value: "",
          };
        }
      }
    }
  }
  return { ...validResponse, value: "" };
};

const validateDefaultValue = (
  fields: ITypeFields,
  propertyType: string,
  min: number,
  max: number,
  defaultValue: number | string,
  defaultFile: FileList | string
): IValidateResponse => {
  let validResponse = validateMinMax(fields, propertyType, min, max);
  if (!validResponse.valid) {
    return validResponse;
  }
  switch (propertyType) {
    case staticPropertyValidation.Types.BOOLEAN.value:
      return validateBoolean(validResponse, defaultValue);

    case staticPropertyValidation.Types.STRING.value:
      return validateString(validResponse, defaultValue, min, max);

    case staticPropertyValidation.Types.ICON.value:
    case staticPropertyValidation.Types.MIMICS.value:
    case staticPropertyValidation.Types.DOCUMENT.value:
    case staticPropertyValidation.Types.IMAGE.value:
      return validateFile(validResponse, propertyType, defaultFile);

    case staticPropertyValidation.Types.INTEGER.value:
      return validateNumber(validResponse, defaultValue, min, max);
    case staticPropertyValidation.Types.SHORT.value:
      return validateShort(validResponse, defaultValue, min, max);
    case staticPropertyValidation.Types.LONG.value:
      return validateLong(validResponse, defaultValue, min, max);
    case staticPropertyValidation.Types.FLOAT.value:
      return validateFloat(validResponse, defaultValue, min, max);
    case staticPropertyValidation.Types.DOUBLE.value:
      return validateDouble(validResponse, defaultValue, min, max);
    case staticPropertyValidation.Types.DATETIME.value:
      return validateDateTime(validResponse, defaultValue);

    default:
      return validResponse;
  }
};

const formatRequestBody = (reqBody: IAddStaticPropertyReq) => {
  const maxLength: any = reqBody.maxLength;
  const minLength: any = reqBody.minLength;
  const required: any = reqBody.required;
  const defaultValue: any = reqBody.defaultValue;

  if (isNaN(maxLength)) {
    reqBody.maxLength = "";
  }

  if (isNaN(minLength)) {
    reqBody.minLength = "";
  }

  if (!defaultValue) {
    reqBody.defaultValue = "";
  }

  if (!required || required === "false") {
    reqBody.required = false;
  }
  return reqBody;
};

const getFieldVisibility = (propertyType: string): ITypeFields => {
  return Types[propertyType].fields;
};

export const staticPropertyValidation = {
  Types,
  getFieldVisibility,
  validateDefaultValue,
  formatRequestBody,
  filetypes,
};

import { AppThunk } from "store";
import { ISeqrOpsNavigationState } from "./seqr-ops.interface";
import { setLoading, setNavigationState } from "./seqr-ops.slice";

const updateNavigationState = (state: ISeqrOpsNavigationState): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      setNavigationState({ ...getState().seqrOps.navigationState, ...state })
    );
  };
};

const setLoader = (loading: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(loading));
  };
};
const setIsDirty = (isDirty: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setIsDirty(isDirty));
  };
};
const setHasChange = (hasChange: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setHasChange(hasChange));
  };
};

const setStaticProperty = (staticProperty: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setStaticProperty(staticProperty));
  };
};
export const seqrOpsActions = {
  updateNavigationState,
  setLoader,
  setIsDirty,
  setHasChange,
  setStaticProperty
};

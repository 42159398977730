import Navbar from "components/navbar.component/navbar";
import LandingScreen from "modules/landing-screen/landing-screen";
import ViewConsole from "modules/view-console/view-console";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import "./default-container.layout.scss";

import SideBar from "modules/sidebar-console/sidebar-console";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { assetModelActions } from "store/asset-model/asset-model.action";

function SeqrOpsLTR({ modelsCount }: { modelsCount: number }) {
  const dispatch = useAppDispatch();
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );

  useEffect(() => {
    dispatch(assetModelActions.getModelList());
  }, [dispatch]);

  if (modelsCount === 0) {
    return (
      <div className="seqrops-english-layout-wrapper">
        <div className="container-fluid">
          <div className="row seqrops-english-navbar-section">
            <Navbar />
          </div>
          <LandingScreen />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="seqrops-english-layout-wrapper">
        <div className="container-fluid">
          <div className="row seqrops-english-navbar-section">
            <Navbar />
          </div>
          <div className="console-area-container">
            <DndProvider backend={HTML5Backend}>
              <div className="side-bar-console-section">
                <SideBar />
              </div>
              <div
                className={
                  navigationState.sidebarConsole
                    ? "console-area-main-content console-area-main-content--expanded"
                    : "console-area-main-content"
                }
              >
                <ViewConsole />
              </div>
            </DndProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeqrOpsLTR;

import { IModelTreeEvent } from "components/model-tree.component/model-tree.types";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { setTargetId } from "store/asset-model/asset-model.slice";
import ModelingTree from "../../components/model-tree.component/model-tree";
import "./tree-console.scss";

function TreeConsole(props: {
  modelId: number | undefined;
  updateModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteModel: ({ id, name, isRootNode }: IModelTreeEvent) => void;
  deleteRelation: (id: number, name: string) => void;
  addRelation: ({
    sourceModelId,
    targetModelId,
  }: {
    sourceModelId: number;
    targetModelId: number;
  }) => void;
}) {
  //DND
  const [, drop] = useDrop(() => ({
    accept: "model",
    drop: (item: { sourceModelId: number; targetModelId: number }) =>
      addModel(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const dispatch = useDispatch();
  const addModel = (relationDetails: {
    sourceModelId: number;
    targetModelId: number;
  }) => {
    props.addRelation({
      sourceModelId: relationDetails.sourceModelId,
      targetModelId: relationDetails.targetModelId,
    });
    dispatch(setTargetId(relationDetails.targetModelId));
  };

  return (
    <>
      <div className="seqrops-tree-console-wrapper" ref={drop}>
        <div className="container-fluid">
          <div className="seqrops-tree-model-title-section">
            <h4>Model Tree</h4>
          </div>
          <div className="seqrops-tree-model-section">
            <div className="d-tree">
              <ModelingTree
                modelId={props.modelId}
                updateModel={props.updateModel}
                deleteModel={props.deleteModel}
                deleteRelation={props.deleteRelation}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeConsole;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SESSION_KEY } from "config/default.config";
import { sessionStorageUtils } from "utils/session-storage.utils";
import { ISeqrOpsNavigationState, ISeqrOpsState } from "./seqr-ops.interface";

const sessionState: any = sessionStorageUtils.getLocalStorage(
  SESSION_KEY.NAVIGATION_STATE
);

//initial state
const initialState: ISeqrOpsState = {
  navigationState: sessionState || {
    selectedModelId: 0,
    sidebarConsole: false,
    sidebarShowAllModels: false,
    propertyConsole: false,
    propertyConsoleStatic: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  loadingState: {
    loading: false,
  },
  dirtyState: {
    isDirty: false,
  },
  hasChange: false,
  staticProperty: false
};

const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setNavigationState: (
      state,
      action: PayloadAction<ISeqrOpsNavigationState>
    ) => {
      let session = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.NAVIGATION_STATE
      );
      if (session) {
        state.navigationState = {
          ...session,
          ...state.navigationState,
          ...action.payload,
        };
      } else {
        state.navigationState = {
          ...state.navigationState,
          ...action.payload,
        };
      }

      sessionStorageUtils.setLocalStorage(
        SESSION_KEY.NAVIGATION_STATE,
        state.navigationState
      );
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loginState = {
        ...state.loginState,
        isLoggedIn: action.payload,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.dirtyState = {
        ...state.dirtyState,
        isDirty: action.payload,
      };
    },
    setHasChange: (state, action: PayloadAction<boolean>) => {
      state.hasChange = action.payload
    },
    setStaticProperty: (state, action: PayloadAction<boolean>) => {
      state.staticProperty = action.payload
    },
  },
});

export const { setNavigationState, setLogin, setLoading, setIsDirty, setHasChange, setStaticProperty } = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;

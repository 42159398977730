import { AppThunk } from "store";
import { MESSAGES } from "../../config/default.config";
import { toasterUtils } from "../../utils/toaster.utils";
import { ILogoutTokenPayload, ITokenDetails, IValidateTokenPayload } from "./asset-keycloak.interface";
import { assetKeycloakService } from "./asset-keycloak.service";
import { setTokens } from "./asset-keycloak.slice";
import { TokenServices } from "./token-services";

export const validateToken = (IValidateTokenPayload : IValidateTokenPayload, refresh_token: string, ITokenDetails : ITokenDetails): AppThunk => {
  return async (dispatch, getState) => {
  const params = new URLSearchParams();
  params.append('token', IValidateTokenPayload.token);
  params.append('client_id', IValidateTokenPayload.client_id);
  params.append('client_secret', IValidateTokenPayload.client_secret);

  try {
    const response = await assetKeycloakService.validateToken(params);
    if (response?.data?.active) {
      dispatch(setTokens(ITokenDetails));
      TokenServices.processToken(IValidateTokenPayload, refresh_token);
    } else {
        dispatch(setTokens(ITokenDetails));
        TokenServices.processToken(IValidateTokenPayload, refresh_token);
      }
    // if(IValidateTokenPayload.token){
    //   interceptiorUtils.initialize();
    // }else {
    // }

  } catch (err) {
    return null;
  }
  }
};


const logoutToken = async (ILogoutTokenPayload: ILogoutTokenPayload, setSuccess : (state: boolean) => void) => {

  const params = new URLSearchParams();
  params.append('refresh_token', ILogoutTokenPayload.refresh_token);
  params.append('client_id', ILogoutTokenPayload.client_id);
  params.append('client_secret', ILogoutTokenPayload.client_secret);

  try {
    const response = await assetKeycloakService.logoutToken(params);
    if (response.status) {
      setSuccess(true);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
    } else {
      return null;  
    }
  } catch (err) {
    return null;
    }
  };


  export const assetKeycloakActions = {
    validateToken,
    logoutToken
  };
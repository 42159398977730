import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { staticPropertyValidation } from "../static-property-validation";

function RegexComponent({
  getValues,
  register,
  setError,
  errorWrap,
  errors,
}: {
  getValues: any;
  register: any;
  setError: any;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
}) {
  const selectedType = getValues("type");
  let regex = /^[A-z]+([/s][a-zA-Z]+)*$/;
  const dispatch = useDispatch()
  switch (selectedType) {
    case staticPropertyValidation.Types.BOOLEAN.value:
      return (
        <>
          <label className="form-label" htmlFor="regularExpression">
            Regular expression
          </label>
          <input
            className="form-control"
            type="text"
            id="regularExpression"
            {...register("regEx", {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setIsDirty(true))
            }} />
          <>{errorWrap(errors?.regEx?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.STRING.value:
    case staticPropertyValidation.Types.INTEGER.value:
    case staticPropertyValidation.Types.SHORT.value:
    case staticPropertyValidation.Types.LONG.value:
    case staticPropertyValidation.Types.FLOAT.value:
    case staticPropertyValidation.Types.DOUBLE.value:
      return (
        <>
          <label className="form-label" htmlFor="regularExpression">
            Regular expression
          </label>
          <input
            className="form-control"
            type="text"
            id="regularExpression"
            {...register("regEx", {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setIsDirty(true))
            }}
          />
          <>{errorWrap(errors?.regEx?.message)}</>
        </>
      );

    case staticPropertyValidation.Types.ICON.value:
    case staticPropertyValidation.Types.MIMICS.value:
    case staticPropertyValidation.Types.DOCUMENT.value:
    case staticPropertyValidation.Types.IMAGE.value:
      return (
        <>
          <label className="form-label" htmlFor="regularExpression">
            Regular expression
          </label>
          <input
            className="form-control"
            type="text"
            id="regularExpression"
            {...register("regEx", {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setIsDirty(true))
            }}
          />
          <>{errorWrap(errors?.regEx?.message)}</>
        </>
      );
    default:
      return null;
  }
}

export default RegexComponent;

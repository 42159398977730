import { Tooltip } from "@mui/material";
import deleteIcon from "assets/images/seqrops_delete_icon.svg";
import editIcon from "assets/images/seqrops_edit_icon.svg";
import noDataFoundIcon from "assets/images/seqrops_no-search-results_icon.svg";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { modelPropertyActions } from "store/model-properties/model-property.action";
import { IDynamicPropertyDetails } from "store/model-properties/model-property.interface";
import "../dynamic-property-table/dynamic-property-table.scss";

function DynamicPropertyTable({
  showDynamicPropertyDetails,
  modelId,
}: {
  showDynamicPropertyDetails: (
    details: IDynamicPropertyDetails | undefined
  ) => void;
  modelId: number | undefined;
}) {
  const [propertyDelFlag, setPropertyDeleteFlag] = useState(false);
  const [dynamicPropertyDetails, setDynamicPropertyDetails] = useState<
    IDynamicPropertyDetails | undefined
  >();
  const dynamicProperties = useAppSelector(
    (state) => state.modelProperties.dynamicProperties
  );

  const dispatch = useAppDispatch();
  const confirmationPopup = DeleteConfirmationDialog();

  const handleEdit = async (dynamicPropertyId: number) => {
    const dynamicPropertyDetails: any =
      await modelPropertyActions.getModelDynamicPropertyById(dynamicPropertyId);
    console.log(
      "dynamicPropertyDetails----dynamicTable",
      dynamicPropertyDetails
    );

    showDynamicPropertyDetails(dynamicPropertyDetails);
  };

  // const handleDelete = (dynamicPropertyDetails: any, e: any) => {
  //   setDynamicPropertyDetails(dynamicPropertyDetails);
  //   setPropertyDeleteFlag(true);
  //   showDynamicPropertyDetails(undefined);
  // };

  const handleDelete = async (dynamicPropertyId: number) => {
    const dynamicPropertyDetails =
      await modelPropertyActions.deleteDynamicProperty(dynamicPropertyId);

    if (dynamicPropertyDetails && modelId) {
      confirmationPopup.closeDialog();
      dispatch(modelPropertyActions.getModelDynamicProperty(modelId));
    }
    if (!dynamicPropertyDetails) {
      confirmationPopup.closeDialog();
      dispatch(modelPropertyActions.getModelDynamicProperty(modelId));

    }
  };

  if (dynamicProperties.length === 0) {
    return (
      <>
        <div className="seqrops-no-data-found-section-wrapper">
          <div className="seqrops-no-data-found-section">
            <img
              src={noDataFoundIcon}
              className="img-fluid"
              alt="seqrops no data found icon"
            />
            <p>No data found</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="seqrops-dynamic-property-table-wrapper mt-4">
        <div className="container-fluid">
          <div className="seqrops-dynamic-property-table-section">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Tag Mode</th>
                  <th>Control Mode</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dynamicProperties.map((dynamicProperty: any) => (
                  <tr
                    key={dynamicProperty.id}
                    className="seqrops-property-table-content-section"
                  >
                    <td>{dynamicProperty.name}</td>
                    <td>{dynamicProperty.type}</td>
                    <td>{dynamicProperty.tagMode}</td>
                    <td>{dynamicProperty.controlMode}</td>
                    <td width="90" className="d-flex seqrops-button-section">
                      <button
                        // onClick={(e: any) => {
                        //   e.preventDefault();
                        //   handleDelete(dynamicProperty);
                        // }}
                        onClick={() => {
                          confirmationPopup.onOpenDialog({
                            title: "Delete Confirmation",
                            message:
                              "Are you sure want to delete the property?",
                            cancelBtn: "Cancel",
                            successBtn: "Success",
                            handleClose: () => {
                              handleDelete(dynamicProperty.id);
                            },
                          });
                        }}
                      >
                        <Tooltip title="Delete">
                          <img src={deleteIcon} alt="seqrops edit button" />
                        </Tooltip>
                      </button>
                      <button
                        onClick={() => {
                          handleEdit(dynamicProperty.id);
                        }}
                      >
                        <Tooltip title="Edit">
                          <img src={editIcon} alt="seqrops edit button" />
                        </Tooltip>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <DynamicPropertyDelete
        showDynamicProperty={propertyDelFlag}
        setShowDynamicProperty={setPropertyDeleteFlag}
        dynamicPropertyDetails={dynamicPropertyDetails}
      /> */}
    </>
  );
}
export default DynamicPropertyTable;

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useDialog } from "./DialogProvider";

function PublishConfirmationDialog() {
    const [openDialog, closeDialog] = useDialog();
    const onOpenDialog = ({
        title,
        message,
        cancelBtn,
        successBtn,
        handleClose,
    }: {
        title: string;
        message: string;
        cancelBtn: string;
        successBtn: string;
        handleClose: () => void;
    }) => {
        openDialog({
            children: (
                <>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>{message}</DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            {cancelBtn}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            {successBtn}
                        </Button>
                    </DialogActions>
                </>
            ),
        });
    };

    return {
        onOpenDialog,
        closeDialog,
    };
}

export default PublishConfirmationDialog;

import { IModelTree, IModelTreeDataItem } from "../model-tree.types";
import TreeItem from "./tree-item";

function TreeList({
  customKey,
  tree,
  actions,
  toggleMap,
  actionMap,
}: IModelTree) {
  return (
    <ul className="ms-0">
      {tree.map((child: IModelTreeDataItem, index: number) => {
        const indexStr = "-" + index;
        return (
          <TreeItem
            key={customKey + "" + indexStr}
            customKey={customKey + "-" + index}
            item={child}
            toggleMap={toggleMap}
            actions={actions}
            actionMap={actionMap}
          />
        );
      })}
    </ul>
  );
}
export default TreeList;

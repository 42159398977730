import modelDragIcon from "assets/images/seqrops_drag_icon.svg";
import { useDrag } from "react-dnd";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import "./sidebar-list.component.scss";

function ModelListItem({
  targetId,
  targetName,
}: {
  targetId: number;
  targetName: string;
}) {
  const dispatch = useAppDispatch();
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );

  //DND
  const [, dragRef] = useDrag({
    type: "model",
    item: {
      targetModelId: targetId,
      sourceModelId: navigationState.selectedModelId,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      {targetId ? (
        <>
          <div className="seqrops-model-list-item-wrapper" key={targetId}>
            <div className="">
              <div
                className={
                  "d-flex model-item my-1 mx-1" +
                  (targetId === navigationState.selectedModelId
                    ? " seqrops-selected-model"
                    : "")
                }
                key={targetId}
                onClick={(e: any) => {
                  dispatch(assetModelActions.setSelectedModel(targetId));
                }}
                ref={dragRef}
              >
                <div className="me-2 drag-icon">
                  <img src={modelDragIcon} alt="SeqrOps drag icon" />
                </div>
                <div className="model-name">
                  <h5>{targetName}</h5>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ModelListItem;

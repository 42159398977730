import ConfirmationDialog from "components/dialogs/confirmation.dialog";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import {
  IAddStaticPropertyReq,
  IStaticPropertyDetails
} from "store/model-properties/model-property.interface";
import { staticPropertyActions } from "store/model-properties/static-property.action";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import {
  ITypeFields,
  STATIC_PROPERTY_MESSAGES,
  staticPropertyValidation
} from "./static-property-validation";
import "./static-property.scss";
import DefaultValueComponent from "./sub-components/default-value.component";
import MaxValueComponent from "./sub-components/max-value.component";
import MinValueComponent from "./sub-components/min-value.component";
import RegexComponent from "./sub-components/regex.component";
import TypeComponent from "./sub-components/type.component";

export default function CreateStaticProperty({
  modelId,
  showStaticForm,
  setShowStaticForm,
  staticPropertyDetails,
}: {
  modelId: number;
  showStaticForm: boolean;
  setShowStaticForm: (isOpen: boolean) => void;
  staticPropertyDetails: IStaticPropertyDetails | undefined;
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm<IStaticPropertyDetails>({
    defaultValues: {
      name: "",
      type: "",
      defaultValue: "",
      required: false,
      maxLength: 0,
      regEx: "",
      minLength: 0,
      defaultFile: "",
      isReserved: false,
    },
  });
  const staticProperties = useAppSelector(
    (state) => state.modelProperties.properties
  );
  const [filePreview, setFilePreview] = useState<{
    url: string;
    referece: string;
    deleted: boolean;
  }>({
    url: "",
    referece: "",
    deleted: true,
  });
  const [isReserved, setIsReserved] = useState(false);
  // const [isDirty, setIsDirty] = useState(false);
  const confirmationPopup = ConfirmationDialog();

  const { errors } = useFormState<IStaticPropertyDetails>({ control });
  const [fields, setFields] = useState<ITypeFields>({
    defaultValue: {
      show: false,
      value: "",
    },
    defaultFile: {
      show: false,
      value: "",
    },
    required: {
      show: false,
      value: "",
    },
    regex: {
      show: false,
      value: "",
    },
    min: {
      show: false,
      value: null,
    },
    max: {
      show: false,
      value: null,
    },
    isReserved: {
      show: false,
      value: false,
    },
  });

  const dispatch = useAppDispatch();
  const propertyId = staticPropertyDetails ? staticPropertyDetails?.id : 0;

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty)

  //handle reset
  const handleReset = () => {
    reset();
    setShowStaticForm(false);
  };

  const setSuccess = () => {
    setShowStaticForm(false);
    handleReset();
  };

  //handle saving
  const handleSave = async (propertyValues: IStaticPropertyDetails) => {
    if (!nameValidation(propertyValues)) {
      return;
    }
    const isDefaultValueValid = staticPropertyValidation.validateDefaultValue(
      fields,
      propertyValues.type,
      propertyValues.minLength,
      propertyValues.maxLength,
      propertyValues.defaultValue,
      propertyValues.defaultFile
    );
    if (isDefaultValueValid.value == "NaN") {
      isDefaultValueValid.value = null
    }
    if (!isDefaultValueValid.valid) {
      setError(isDefaultValueValid.field as any, {
        type: "custom",
        message: isDefaultValueValid.message,
      });
      // setIsDirty(false);

      return;
    }

    let pValue: any = propertyValues;
    let min: any = pValue.minLength === "" ? null : pValue.minLength;
    let max: any = pValue.maxLength === "" ? null : pValue.maxLength;


    const reqBody: IAddStaticPropertyReq =
      staticPropertyValidation.formatRequestBody({
        id: propertyId,
        name: propertyValues.name,
        type: propertyValues.type,
        defaultValue: isDefaultValueValid.value || null,
        minLength: isNaN(min) ? null : min,
        maxLength: isNaN(max) ? null : min,
        required: propertyValues.required,
        regEx: propertyValues.regEx,
        modelId: modelId,
        defaultFile: staticPropertyDetails?.defaultFile || "",
        reference: staticPropertyDetails?.reference || "",
        isReserved: false,
      });

    const fileData = new FormData();
    const file = propertyValues.defaultFile[0] as File;
    fileData.append("file", file);

    if (reqBody.id === 0) {
      fileData.append("reference", "");
      dispatch(
        staticPropertyActions.createStaticProperty(
          fields.defaultFile.show,
          file ? fileData : undefined,
          reqBody,
          setError,
          setSuccess
        )
      );
    } else {
      fileData.append("reference", staticPropertyDetails?.reference || "");

      const hasExistingFile = filePreview.url ? true : false;
      const hasNewFile = fields.defaultFile.show && file ? true : false;

      const createNewFile = !hasExistingFile && hasNewFile ? true : false;
      // const deleteExistingFile = hasExistingFile && hasNewFile ? true : false;
      const replaceExistingFile = hasExistingFile && hasNewFile;
      const normalUpdate = !hasExistingFile && !hasNewFile ? true : false;

      if (createNewFile) {
        dispatch(
          staticPropertyActions.createFileAndUpdateStaticProperty(
            fileData,
            reqBody,
            setError,
            setSuccess
          )
        );
      }
      //  else if (deleteExistingFile) {
      //   dispatch(
      //     staticPropertyActions.deleteFileAndUpdateStaticProperty(
      //       reqBody,
      //       setError,
      //       setSuccess
      //     )
      //   );
      // }
      else if (replaceExistingFile) {
        dispatch(
          staticPropertyActions.replaceFileAndUpdateStaticProperty(
            fileData,
            reqBody,
            setError,
            setSuccess
          )
        );
      } else {
        dispatch(
          staticPropertyActions.updateStaticProperty(
            reqBody,
            setError,
            setSuccess
          )
        );
      }
    }
  };

  //handling cancel
  const handleCancel = () => {
    handleReset();
    confirmationPopup.closeDialog();

  };

  const handleFormChange = () => {
    return dispatch(setIsDirty(true))
  }

  useEffect(() => {
    if (staticPropertyDetails) {
      staticPropertyDetails.isReserved
        ? setIsReserved(true)
        : setIsReserved(false);
      handleTypeChange(staticPropertyDetails.type, true);
      setValue("id", staticPropertyDetails.id);
      setValue("modelId", staticPropertyDetails.modelId);
      setValue("defaultFile", "");
      setValue("name", staticPropertyDetails.name);
      setValue("type", staticPropertyDetails.type);
      setValue("defaultValue", staticPropertyDetails.defaultValue);
      setValue("required", staticPropertyDetails.required);
      setValue("maxLength", staticPropertyDetails.maxLength);
      setValue("regEx", staticPropertyDetails.regEx);
      setValue("minLength", staticPropertyDetails.minLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps


  }, [setValue, staticPropertyDetails, isDirty]);

  if (!showStaticForm) {
    return null;
  }

  const nameValidation = (propertyValues: IStaticPropertyDetails) => {
    if (
      propertyValues.name.toLowerCase() === "name" ||
      propertyValues.name.toLowerCase() === "description" ||
      propertyValues.name.toLowerCase() === "createdat" ||
      propertyValues.name.toLocaleLowerCase() === "updatedat"
    ) {
      setError("name", {
        type: "custom",
        message: "Reserved name cannot be used, please try a new name",
      });
      return false;
    }
    if (!/^(?!_)(?!.*_$)[A-Za-z0-9_]+$/.test(propertyValues.name)) {
      setError("name", {
        type: "custom",
        message: "Property name must not start or end with underscore",
      });
      return false;
    }
    return true;
  };

  const handleTypeChange = (
    selected_option: string,
    updateTrigger: boolean = false
  ) => {
    setValue("type", selected_option, { shouldValidate: true });
    clearErrors([
      "defaultValue",
      "defaultFile",
      "required",
      "minLength",
      "maxLength",
      "regEx",
    ]);
    const typeFields =
      staticPropertyValidation.getFieldVisibility(selected_option);

    setValue("defaultValue", typeFields.defaultValue.value);
    setValue("defaultFile", typeFields.defaultFile.value);
    setValue("required", typeFields.required.value);
    setValue("minLength", typeFields.min.value);
    setValue("maxLength", typeFields.max.value);
    setValue("regEx", typeFields.regex.value);
    setFields(typeFields);

    if (updateTrigger && staticPropertyDetails) {
      setFilePreview({
        url: (staticPropertyDetails.defaultFile as string) || "",
        referece: staticPropertyDetails.reference || "",
        deleted: false,
      });
    } else {
      setFilePreview({
        ...filePreview,
        deleted: true,
      });
    }
  };

  const renderName = () => {
    return (
      <>
        <label className="form-label" htmlFor="propertyName">
          Name
        </label>
        <input
          disabled={staticPropertyDetails?.isReserved}
          className="form-control"
          type="text"
          minLength={3}
          maxLength={50}
          id="propertyName"
          {...register("name", {
            required: STATIC_PROPERTY_MESSAGES.NAME_REQUIRED,
            pattern: {
              value: /^\w*$/,
              message: STATIC_PROPERTY_MESSAGES.NAME_PATTERN,
            },
            minLength: {
              value: 3,
              message: STATIC_PROPERTY_MESSAGES.NAME_MIN_MAX,
            },
            maxLength: {
              value: 50,
              message: STATIC_PROPERTY_MESSAGES.NAME_MIN_MAX,
            },
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue("name", e.target.value.replace(/\W/g, ""));
            clearErrors("name");
            handleFormChange();
          }}
        />
        <>{errorWrap(errors?.name?.message)}</>
      </>
    );
  };

  const renderType = () => {
    return (
      <TypeComponent
        getValues={getValues}
        setValue={setValue}
        register={register}
        setError={setError}
        handleTypeChange={handleTypeChange}
        errorWrap={errorWrap}
        errors={errors}
        staticPropertyDetails={staticPropertyDetails}
      />
    );
  };

  const renderDefaultValue = () => {
    if (!(fields.defaultValue.show || fields.defaultFile.show)) return null;
    return (
      <div className="col-md-6">
        <DefaultValueComponent
          getValues={getValues}
          register={register}
          setError={setError}
          errorWrap={errorWrap}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          filePreview={filePreview}
          setFilePreview={setFilePreview}
          staticPropertyDetails={staticPropertyDetails}
        />
      </div>
    );
  };

  const renderRequired = () => {
    if (!fields.required.show) return null;
    return (
      <div className="col-md-6 d-flex align-items-center">
        <label htmlFor="required">
          <input
            disabled={staticPropertyDetails?.isReserved}
            type="checkbox"
            className="round-checkbox me-2"
            id="required"
            {...register("required")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange();
            }} />
          Is Required
        </label>
        <>{errorWrap(errors?.required?.message)}</>
      </div>
    );
  };

  const renderMinValue = () => {
    if (!fields.min.show) return null;
    return (
      <div className="col-md-6">
        <MinValueComponent
          getValues={getValues}
          register={register}
          setError={setError}
          errorWrap={errorWrap}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </div>
    );
  };

  const renderMaxValue = () => {
    if (!fields.max.show) return null;
    return (
      <div className="col-md-6">
        <MaxValueComponent
          getValues={getValues}
          register={register}
          setError={setError}
          errorWrap={errorWrap}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </div>
    );
  };

  const renderRegex = () => {
    if (!fields.regex.show) return null;
    return (
      <div className="col-md-6">
        <RegexComponent
          getValues={getValues}
          register={register}
          setError={setError}
          errorWrap={errorWrap}
          errors={errors}
        />
      </div>
    );
  };

  return (
    <>
      <div className="seqrops-property-adding-section mt-3 static-section">
        <div className="container-fluid">

          <div className="seqrops-property-adding-form-section">
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="row my-3">
                <div className="col-md-6">{renderName()}</div>
                <div className="col-md-6 seqrops-basic-form-section">
                  {renderType()}
                </div>
              </div>
              <div className="row seqrops-property-action-section">
                {renderDefaultValue()}
                {renderRequired()}
                {renderMinValue()}
                {renderMaxValue()}
                {renderRegex()}
              </div>
              {!staticPropertyDetails?.isReserved ? (
                <div className="seqrops-property-adding-btn-section col-md-12 my-2 p-0 py-1 px-1">
                  <button
                    className="seqrops-btn btn-outline me-2 w-custom-btn"
                    type="button"
                    onClick={() => {
                      console.log(isDirty);
                      if (isDirty) {
                        confirmationPopup.onOpenDialog({
                          handleClose() {
                            reset();
                            confirmationPopup.closeDialog()
                            setShowStaticForm(false);
                            dispatch(setIsDirty(false))
                          },
                        })
                      } else {
                        reset();
                        setShowStaticForm(false);
                      }
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="seqrops-btn btn-fill w-custom-btn"
                  >
                    {staticPropertyDetails?.id != null ? "Update" : "Add"}
                  </button>
                </div>
              ) : (
                <div className="seqrops-property-adding-btn-section col-md-12 my-2 p-0 py-1 px-1">
                  <button
                    className="seqrops-btn btn-outline me-2 w-custom-btn"
                    onClick={() => {
                      // console.log("isDirty", isDirty);

                      // if (isDirty === true) {
                      //   confirmationPopup.onOpenDialog({

                      //     handleClose() {

                      //       handleCancel();
                      //     },
                      //   })
                      // } else {
                      //   reset();
                      //   setShowStaticForm(false);

                      // }
                    }}
                  >
                    Back
                  </button>
                </div>
              )}
            </form>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </>
  );
}

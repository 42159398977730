import { FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IStaticPropertyDetails } from "store/model-properties/model-property.interface";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { staticPropertyValidation } from "../static-property-validation";

function MinValueComponent({
  getValues,
  register,
  setError,
  errorWrap,
  errors,
  setValue,
  clearErrors,
}: {
  getValues: any;
  register: any;
  setError: any;
  setValue: any;
  clearErrors: any;
  errorWrap: (code: string | undefined) => void;
  errors: FieldErrors<IStaticPropertyDetails>;
}) {
  const selectedType = getValues("type");
  const dispatch = useDispatch()
  switch (selectedType) {
    case staticPropertyValidation.Types.BOOLEAN.value:
    case staticPropertyValidation.Types.ICON.value:
    case staticPropertyValidation.Types.MIMICS.value:
    case staticPropertyValidation.Types.DOCUMENT.value:
    case staticPropertyValidation.Types.IMAGE.value:
      return null;
    case staticPropertyValidation.Types.STRING.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Length
          </label>
          <input
            className="form-control"
            id="minLength"
            pattern="^[0-9]*$"
            {...register("minLength", {
              valueAsNumber: true,
              pattern: {
                value: /^\d*$/,
                message: "Please enter a valid min length",
              },
              min: {
                value: 0,
                message: "Min length should not be less than 0",
              },
              max: {
                value: 255,
                message:
                  "Min length should be less than or equal to 255 characters",
              },
            })}
            onChange={(e) => {
              const value = parseInt(e.target.value.split(/\D/).join(""));
              clearErrors("minLength");
              setValue("minLength", isNaN(value) ? "" : value);
              dispatch(setIsDirty(true))
            }}
          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.INTEGER.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Value
          </label>
          <input
            className="form-control"
            id="minLength"
            type="number"
            pattern="^-\d+$"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("minLength", {
              // required: "Please enter a  min value",
              // valueAsNumber: true,
              // pattern: {
              //   value: /^-\d+$/,
              //   message: "Please enter a valid min value",
              // },
              min: {
                value: -2147483648,
                message: "Min length should not be less than -2147483648",
              }, max: {
                value: 2147483647,
                message:
                  "Min length should be less than or equal to 2147483647",
              },

            })}

            onChange={(e) => {

              const value = e.target.value;
              clearErrors("minLength");
              setValue("minLength", value);
              dispatch(setIsDirty(true))

            }}

          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.SHORT.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Value
          </label>
          <input
            className="form-control"
            id="minLength"
            pattern="(^[0]{1}$|^[-]?[1-9]{1}\d*$)"
            type="number"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("minLength", {
              valueAsNumber: true,
              pattern: {
                value: "(^[0]{1}$|^[-]?[1-9]{1}d*$)",
                message: "Please enter a valid min value",
              },
              min: {
                value: -32768,
                message: "Min length should not be less than -32,768",
              }, max: {
                value: 32767,
                message: "Min length should be less than or equal to 32,767",
              },

            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("minLength");
              setValue("minLength", value);
              dispatch(setIsDirty(true))

            }}
          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.LONG.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Value
          </label>
          <input
            className="form-control"
            id="minLength"
            pattern="^\d*$"
            type="number"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("minLength", {
              valueAsNumber: true,
              pattern: {
                value: /^\d*$/,
                message: "Please enter a valid min value",
              },
              min: {
                value: -2147483648,
                message: "Min length should not be less than -2^63",
              },
              max: {
                value: 2147483647,
                message: "Min length should be less than or equal to 2^63-1",
              },

            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("minLength");
              setValue("minLength", value);
              dispatch(setIsDirty(true))

            }}
          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.FLOAT.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Value
          </label>
          <input
            className="form-control"
            id="minLength"
            pattern="^-?\d*\.{0,1}\d+$"
            type="number"
            step="any"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("minLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-?\d*\.{0,1}\d+$/,
                message: "Please enter a valid min value",
              },
              min: {
                value: 2 ^ -149,
                message: "Min length should not be less than 2^-149",
              },
              max: {
                value: 2 ^ 127,
                message:
                  "Min length should be less than or equal to 2^127",
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("minLength");
              setValue("minLength", value);
              dispatch(setIsDirty(true))

            }}
          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    case staticPropertyValidation.Types.DOUBLE.value:
      return (
        <>
          <label className="form-label" htmlFor="minLength">
            Min Value
          </label>
          <input
            className="form-control"
            id="minLength"
            pattern="^-?\d*\.{0,1}\d+$"
            type="number"
            step="any"
            // value={isNaN(defaultValue) ? "" : defaultValue}
            {...register("minLength", {
              valueAsNumber: true,
              pattern: {
                value: /^-?\d*\.{0,1}\d+$/,
                message: "Please enter a valid min value",
              },
              min: {
                value: 2 ^ -1074,
                message: "Min length should not be less than 2^-1074 ",
              }, max: {
                value: 2 ^ 1023,
                message:
                  "Min length should be less than or equal to 2^1023",
              },

            })}
            onChange={(e) => {
              const value = e.target.value;
              clearErrors("minLength");
              setValue("minLength", value);
              dispatch(setIsDirty(true))

            }}
          />
          <>{errorWrap(errors?.minLength?.message)}</>
        </>
      );
    default:
      return null;
  }
}

export default MinValueComponent;
import warning from "assets/images/warning_icon.svg";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import "./delete-model.scss";
interface IDeleteModelScreenProps {
  showModel: boolean;
  setShowModel: (show: boolean) => void;
  modelDetails:
    | {
        id: number;
        name: string;
        isRootNode: boolean;
        description:string;
        icon:string | FileList;
      }
    | undefined;
}

export interface IDeleteModelForm {
  id: number;
  name: string;
  isRootNode: boolean;
}

function DeleteModelScreen({
  showModel,
  setShowModel,
  modelDetails,
}: IDeleteModelScreenProps) {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setShowModel(false);
  };

  const setSuccess = (state: boolean) => {
    setShowModel(!state);
  };

  const handleConfirm = () => {
    dispatch(assetModelActions.deleteModel(modelDetails?.icon||"",modelDetails?.id || 0, setSuccess));
  };

  return (
    <>
      <div className="main-wrapper">
        <Modal
          show={showModel}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="delete_model">
            <div className="d-flex align-items-center">
            <img className="warning" src={warning} alt="" /> 
            <div>
            Are you sure you
            want to delete model <b>{modelDetails?.name}</b> ?</div>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <Button className="seqrops-btn btn-outline" onClick={handleClose}>
              No
            </Button>
            <Button className="seqrops-btn btn-fill" onClick={handleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default DeleteModelScreen;

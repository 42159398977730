import axios from "axios";
import { ASSET_MODEL_API } from "config/default.config";
import { commonUtils } from "utils/common.utils";
import {
  ICreateModelRelationReq,
  ICreateModelReq
} from "./asset-model.interface";

const fetchAllModelsCount = async () => {
  const response = await axios.get(ASSET_MODEL_API.GET_MODELS_COUNT);
  return response.data;
};

const createModel = async (modelDetails: ICreateModelReq) => {
  const response = await axios.post(ASSET_MODEL_API.CREATE_MODEL, modelDetails, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  return response;
};
const updateModel = async (modelDetails: ICreateModelReq) => {
  const response = await axios.put(ASSET_MODEL_API.UPDATE_MODEL, modelDetails, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  return response;
};
const fetchAllModels = async () => {
  const response = await axios.get(ASSET_MODEL_API.GET_ALL_MODELS);
  return response?.data;
};

const fetchModelHierarchy = async (modelId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_MODEL_HIERARCHY, modelId)
  );
  return response?.data;
};
const deleteModel = async (modelId: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_MODEL, modelId)
  );
  return response.data;
};
const createRelation = async (relation: ICreateModelRelationReq) => {
  const response = await axios.post(ASSET_MODEL_API.CREATE_RELATION, relation, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  return response;
};

const publishModel = async (domainId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.PUBLISH_MODEL, domainId)
  );
  return response.data;
};

const deleteRelation = async (relationId: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_MODEL_RELATION, relationId)
  );
  return response.data;
};
export const assetModelService = {
  fetchAllModelsCount,
  createModel,
  updateModel,
  fetchAllModels,
  fetchModelHierarchy,
  deleteModel,
  createRelation,
  publishModel,
  deleteRelation,
};

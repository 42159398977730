import { default as axios, default as request } from "axios";
import { ASSET_MODEL_API, MESSAGES } from "config/default.config";
import { fileManagemntActions } from "store/file-management/file-management.action";
import { modelPropertyActions } from "store/model-properties/model-property.action";
import { staticPropertyActions } from "store/model-properties/static-property.action";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import { setLoading, setNavigationState } from "store/seqr-ops/seqr-ops.slice";
import { toasterUtils } from "utils/toaster.utils";
import { AppThunk } from "..";
import {
  ICreateModelRelationReq,
  ICreateModelReq,
  IModelListItem
} from "./asset-model.interface";
import { assetModelService } from "./asset-model.service";
import {
  setFilteredModels,
  setModelHierarchy,
  setModels,
  updateSelectedModel
} from "./asset-model.slice";

const getAllModelsCount = async () => {
  try {
    const response = await assetModelService.fetchAllModelsCount();
    if (response.success) {
      return response.data;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

const generateErrors = (errorCode: string, setError: any) => {
  let fieldName: string = "";
  let message: string = "";

  if (errorCode === "RELATION_EXISTS") {
    setError("relationshipName", {
      type: "custom",
      message: MESSAGES.MODEL_RELATION_EXISTS,
    });
  } else if (errorCode === "RESERVED_MODEL_NAME") {
    setError("relationshipName", {
      type: "custom",
      message: MESSAGES.RESERVED_MODEL_NAME,
    });
  } else if (errorCode === "TARGET_SHOULD_BE_A_ROOT_NODE") {
    setError("targetId", {
      type: "custom",
      message: MESSAGES.TARGET_SHOULD_BE_A_ROOT_NODE,
    });
  } else if (errorCode === "AGGREGATION_NOT_ALLOWED") {
    setError("isAssoc", {
      type: "custom",
      message: MESSAGES.AGGREGATION_NOT_ALLOWED,
    });
  }
  switch (errorCode) {
    case "MODEL_NAME_EXISTS":
      fieldName = "name";
      message = MESSAGES.MODEL_NAME_EXISTS;
      break;
    case "MODEL_NAME_EDGE_NAME_SHOULD_NOT_SAME":
      fieldName = "name";
      message = MESSAGES.MODEL_NAME_EDGE_NAME_SHOULD_NOT_SAME;
      break;
    case "RESERVED_MODEL_NAME":
      fieldName = "name";
      message = MESSAGES.RESERVED_MODEL_NAME;
      break;
    case "ROOT_NODE_CANNOT_BE_EDITED":
      fieldName = "isRootNode";
      message = MESSAGES.ROOT_NODE_CANNOT_BE_EDITED;
      break;

    default:
      break;
  }

  if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: message,
    });
  }
};

const createModel = (
  modelDetails: ICreateModelReq,
  fileData: FormData | any,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      if (fileData !== undefined) {
        axios
          .get(ASSET_MODEL_API.REFERENCE_URL)
          .then(async (response) => {
            fileData?.append("reference", response.data.data || "");
            const fileDataResponse = await fileManagemntActions.uploadFile(
              fileData
            );
            if (fileDataResponse) {
              const url = fileDataResponse.url || "";
              // modelDetails.id = createResponse.data.id;
              modelDetails.icon = url;

              const createResponse = await assetModelService.createModel(
                modelDetails
              );


              if (createResponse.data.success) {
                toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
                setSuccess(true);

                dispatch(getModelList(createResponse.data?.data?.id));
              }
              else if (!createResponse.data.success) {
                const errorCode = createResponse?.data?.errorCode;
                generateErrors(errorCode, setError);
              }
            }
          })
          .catch((error) => {
            console.log("error", error.response);

            if (error.response) {
              toasterUtils.showError(error.response?.data?.errorCode);
            }
          });
      } else {
        const createResponse = await assetModelService.createModel(
          modelDetails
        );

        if (createResponse.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
          setSuccess(true);

          dispatch(getModelList(createResponse.data?.data?.id));
        }
        else if (!createResponse.data.success) {
          const errorCode = createResponse?.data?.errorCode;
          generateErrors(errorCode, setError);

        }
        // modelDetails.icon = "";
        // modelDetails.id = createResponse.data.id;
        // const modelUpdateResponse = await assetModelService.updateModel(
        //   modelDetails
        // );
      }
      //   if (modelUpdateResponse) {
      //     toasterUtils.showSuccess(MESSAGES.MODEL_CREATE_SUCCESS);
      //     setSuccess(true);

      //     dispatch(getModelList(createResponse?.data?.id));
      //   }
      // }

      // }
    } catch (error: any) {
      console.log("error", error.request);
      console.log("error-message", error.message);


      if (error.response) {
        toasterUtils.showError(error.response?.data?.errorCode);
      }
    }
  };
};

const updateModel = (
  modelDetails: ICreateModelReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.updateModel(modelDetails);
      console.log("response", response);

      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(response.data?.data?.id));
      } else if (!response.data.success) {
        const errorCode = response?.data?.errorCode;
        generateErrors(errorCode, setError);

      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const errorCode = error.response?.data?.errorCode;
        generateErrors(errorCode, setError);
      }
    }
  };
};

const createIconAndUpdateModel = (
  fileData: FormData,
  modelDetails: ICreateModelReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    // try {
    axios
      .get(ASSET_MODEL_API.REFERENCE_URL)
      .then(async (referenceResponse) => {
        fileData.append("reference", referenceResponse.data.data);
        const fileDataResponse = await fileManagemntActions.uploadFile(
          fileData
        );
        const url = fileDataResponse.url || "";
        modelDetails.icon = url;

        const response = await assetModelService.updateModel(modelDetails);
        if (response.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
          setSuccess(true);
          dispatch(getModelList(response.data?.data?.id));
        }
        else if (!response.data.success) {
          const errorCode = response?.data?.errorCode;
          generateErrors(errorCode, setError);

        }
      })
      .catch((error) => {
        if (request.isAxiosError(error) && error.response) {
          toasterUtils.showError(error.response?.data?.errorCode);
        }
      });

    // } catch (error) {
    //   if (request.isAxiosError(error) && error.response) {
    //     toasterUtils.showError(error.response?.data?.errorCode);
    //   }
    // }
    return undefined;
  };
};

const deleteIconAndUpdateModel = (
  details: ICreateModelReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const url = details.icon.toString();
      const filename = url.substring(url.lastIndexOf("/") + 1);
      await fileManagemntActions.deleteFile(filename);
      details.icon = "";
      const response = await assetModelService.updateModel(details);

      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(response.data?.data?.id));
      }
      else if (!response.data.success) {
        const errorCode = response?.data?.errorCode;
        generateErrors(errorCode, setError);

      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(error.response?.data?.errorCode);
      }
    }
    return undefined;
  };
};

const replaceIconAndUpdateModel = (
  fileData: FormData,
  details: ICreateModelReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const fileDataResponse = await fileManagemntActions.replaceFile(fileData);
      const url = fileDataResponse.url || "";
      details.icon = url;

      const propertyUpdateUrlResponse = await assetModelService.updateModel(
        details
      );

      if (propertyUpdateUrlResponse.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_UPDATE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(propertyUpdateUrlResponse.data?.data?.id));
      }
      else if (!propertyUpdateUrlResponse.data.success) {
        const errorCode = propertyUpdateUrlResponse?.data?.errorCode;
        generateErrors(errorCode, setError);

      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        showError(error.response?.data?.errorCode, setError);
      }
    }
  };
};

const getModelList = (
  selectedModelId: number | undefined = undefined
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.fetchAllModels();
    dispatch(setModels(response?.data || []));
    const navigationState = getState().seqrOps.navigationState;

    let selectedModel = response?.data?.[0];
    let sidebarShowAllModels = true
    if (selectedModelId === undefined) {
      console.log("on 1st load");
      selectedModel = response?.data?.filter(
        (x: { isEquipment: boolean }) => x.isEquipment === true
      )?.[0];
      sidebarShowAllModels = false
    }
    else if (selectedModelId) {

      selectedModel = response?.data?.filter(
        (x: { id: number }) => x.id === selectedModelId
      )?.[0];

      if (selectedModel?.isEquipment == true) {
        sidebarShowAllModels = false
      }
    }

    dispatch(
      seqrOpsActions.updateNavigationState({
        ...navigationState,
        sidebarShowAllModels: sidebarShowAllModels,
        selectedModelId: selectedModel?.id || 0,
      })
    );

    dispatch(updateSelectedModel(selectedModel || undefined));
    if (selectedModel?.id > 0) {
      dispatch(getModelHierarchy(selectedModel?.id));
    }
  };
};

const searchByModelName = (name: String): AppThunk => {
  return async (dispatch, getState) => {
    const rawAssetModels = getState().assetModels.rawModels;
    const rawSelectedModels = getState().assetModels.selectedModels;

    const filteredModels: IModelListItem[] = rawAssetModels.filter(
      (model: IModelListItem) => {
        return `${model.name}`.toLowerCase().includes(name.toLowerCase());
      }
    );

    const filteredSelectedModels: IModelListItem[] = rawSelectedModels.filter(
      (model: IModelListItem) => {
        return `${model.name}`.toLowerCase().includes(name.toLowerCase());
      }
    );
    dispatch(setFilteredModels({ filteredModels, filteredSelectedModels }));
  };
};

const getModelHierarchy = (modelId: number): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetModelService.fetchModelHierarchy(modelId);
    dispatch(setModelHierarchy(response.data));
    dispatch(staticPropertyActions.getModelStaticProperties(modelId));
    dispatch(modelPropertyActions.getModelDynamicProperty(modelId));
  };
};

const getSubModelHierarchy = async (modelId: number): Promise<AppThunk> => {
  const response = await assetModelService.fetchModelHierarchy(modelId);
  return response?.data?.[0]?.edges || [];
};

const setSelectedModel = (modelId: number): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedModel = state.assetModels.rawModels.filter(
      (x) => x.id === modelId
    );
    dispatch(updateSelectedModel(selectedModel?.[0] || undefined));
    dispatch(
      setNavigationState({
        ...state.seqrOps.navigationState,
        selectedModelId: selectedModel?.[0]?.id || 0,
      })
    );

    if (modelId !== state.seqrOps.navigationState.selectedModelId) {
      dispatch(getModelHierarchy(modelId));
    }
  };
};

const deleteModel = (
  icon: string | FileList,
  modelId: number,
  setSuccess: (state: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      if (icon !== "") {
        const url = icon.toString();
        const reference = url.substring(url.lastIndexOf("/") + 1);
        await fileManagemntActions.deleteFile(reference);
        const response = await assetModelService.deleteModel(modelId);
        const selectedModel = state.assetModels.rawModels.filter(
          (x) => x.id === modelId
        );
        console.log(selectedModel);
        if (response.data.success) {
          toasterUtils.showSuccess(MESSAGES.MODEL_DELETE_SUCCESS);
          setSuccess(true);


          dispatch(getModelList());
        }
      } else {
        const response = await assetModelService.deleteModel(modelId);
        if (response.success) {
          const selectedModel = state.assetModels.rawModels.filter(
            (x) => x.id === modelId
          );
          let index = state.assetModels.rawModels.indexOf(selectedModel[0]);
          const selectedModelNew = state.assetModels.rawModels.filter((x) => state.assetModels.rawModels.indexOf(x) === index + 1)[0];


          toasterUtils.showSuccess(MESSAGES.MODEL_DELETE_SUCCESS);
          setSuccess(true);
          console.log(selectedModelNew);
          if (selectedModelNew != undefined) {
            dispatch(getModelList(selectedModelNew.id));
          } else {
            dispatch(getModelList(state.assetModels.rawModels[0].id))
          }

        }
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const createRelation = (
  relation: ICreateModelRelationReq,
  setError: any,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.createRelation(relation);
      if (response.data.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_RELATION_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(response.data?.data?.sourceId));
      }

      else if (!response.data.success) {
        const errorCode = response?.data?.errorCode;


        generateErrors(errorCode, setError);

      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const errorCode = error.response?.data?.errorCode;
        if (errorCode === "RELATION_EXISTS") {
          setError("relationshipName", {
            type: "custom",
            message: MESSAGES.MODEL_RELATION_EXISTS,
          });
        } else if (errorCode === "RESERVED_MODEL_NAME") {
          setError("relationshipName", {
            type: "custom",
            message: MESSAGES.RESERVED_MODEL_NAME,
          });
        } else if (errorCode === "TARGET_SHOULD_BE_A_ROOT_NODE") {
          setError("targetId", {
            type: "custom",
            message: MESSAGES.TARGET_SHOULD_BE_A_ROOT_NODE,
          });
        } else if (errorCode === "AGGREGATION_NOT_ALLOWED") {
          setError("isAssoc", {
            type: "custom",
            message: MESSAGES.AGGREGATION_NOT_ALLOWED,
          });
        }
      } else {
        setError("relationshipName", {
          type: "custom",
          message: MESSAGES.MODEL_RELATION_EXISTS,
        });
      }
    }
  };
};

const publishModel = (domainId: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const response = await assetModelService.publishModel(domainId);
      if (response.success) {
        dispatch(setLoading(false))
        toasterUtils.showSuccess(MESSAGES.MODEL_PUBLISH_SUCCESS);
      } else {
        dispatch(setLoading(false))
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const deleteRelation = (
  relationId: number,
  sourceId: number,
  setSuccess: (state: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.deleteRelation(relationId);
      if (response.success) {
        toasterUtils.showSuccess(MESSAGES.MODEL_RELATION_DELETE_SUCCESS);
        setSuccess(true);
        dispatch(getModelList(sourceId));
      }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
  };
};

const showError = (errorCode: string, setError: any) => {
  let errorMessage: string = "";
  let fieldName: string = "";
  switch (errorCode) {
    case "NAME_FIELD_REQUIRED":
      fieldName = "name";
      errorMessage = "Please enter name";
      break;
    case "RESERVED_PROPERTY_NAME":
      fieldName = "name";
      errorMessage = MESSAGES.RESERVED_PROPERTY_NAME;
      break;
    case "PROPERTY_NAME_EXISTS":
      fieldName = "name";
      errorMessage = MESSAGES.PROPERTY_NAME_EXISTS;
      break;

    default:
      break;
  }


  if (fieldName) {
    setError(fieldName, {
      type: "custom",
      message: errorMessage,
    });
  }
};

export const assetModelActions = {
  getAllModelsCount,
  createModel,
  updateModel,
  getModelList,
  searchByModelName,
  getModelHierarchy,
  getSubModelHierarchy,
  setSelectedModel,
  deleteModel,
  createRelation,
  publishModel,
  deleteRelation,
  createIconAndUpdateModel,
  deleteIconAndUpdateModel,
  replaceIconAndUpdateModel,
};
